import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import { User, UserProject } from "@/types/User";

const loadPctUser = (): Promise<User> =>
  apiClient.get<void, AxiosResponse<User>>(`/user/pct`).then((response) => response.data);

const invitePctUser = (email: string): Promise<User> =>
  apiClient
    .post<void, AxiosResponse<User>>(`/user/invite/pct`, {
      email: email,
    })
    .then((response) => response.data);

const loadUsers = (group: string): Promise<User[]> =>
  apiClient
    .get<void, AxiosResponse<User[]>>(`/user/group/${group}`)
    .then((response) => response.data);

const addUserToGroups = (userId: string, userGroups: string[]): Promise<User> =>
  apiClient
    .post<void, AxiosResponse<User>>(`/user/${userId}/add-group`, {
      groups: userGroups,
    })
    .then((response) => response.data);

const removeUserFromGroups = (userId: string, userGroups: string[]): Promise<User> =>
  apiClient
    .post<void, AxiosResponse<User>>(`/user/${userId}/remove-group`, {
      groups: userGroups,
    })
    .then((response) => response.data);

const updateUserProjectPermission = (
  username: string,
  customerName: string,
  siteId: string,
  projectPermission: UserProject,
): Promise<User> =>
  apiClient
    .patch<void, AxiosResponse<User>>(
      `/user/${username}/${customerName}/${siteId}/project-permission`,
      projectPermission,
    )
    .then((response) => response.data);

export default {
  loadPctUser,
  invitePctUser,
  loadUsers,
  addUserToGroups,
  removeUserFromGroups,
  updateUserProjectPermission,
};
