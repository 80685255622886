<template>
  <div class="flex gap-2 p-0.5 overflow-y-hidden overflow-x-auto" style="min-height: 300px">
    <div v-for="(split, index) in tagSplits" :key="index" class="flex-1 relative">
      <input
        type="text"
        class="px-2 mb-2 py-1.5 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-yellow-500"
        v-model="split.name"
        @input="handleNameChange"
      />

      <div class="flex justify-center items-center flex-row gap-2 py-2">
        <div v-for="preset in buildingElements" :key="preset">
          <button
            class="inline-flex items-center rounded-md px-3 py-2 text-xs text-white shadow-sm bg-gray-400 hover:bg-gray-600 capitalize"
            @click="selectLevelPreset(preset, index)"
          >
            {{ preset }}
          </button>
        </div>
      </div>

      <XMarkIcon
        class="absolute translate-y-1 w-4 h-4 right-1 cursor-pointer text-gray-600 hover:text-gray-900"
        @click="emits('removeSplit', index)"
      />

      <draggable
        style="max-height: 600px"
        class="w-full h-full bg-gray-200 p-2 px-4 min-w-96 overflow-y-auto flex flex-wrap justify-center content-start gap-1"
        :list="split.processes"
        group="splits"
        :itemKey="split.name"
        @end="handleDnD()"
      >
        <template #item="{ element }">
          <div
            class="px-2 py-1 text-xs font-medium rounded-md h-min cursor-pointer"
            :class="colorConfigBuildingElement[element.buildingElement as BuildingElement]"
          >
            {{ element.decodedLabel }}
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { PropType, ref, watch } from "vue";
import draggable from "vuedraggable";
import { BuildingElement, ProcessClass, EncodedLabel } from "shared/types/ProcessClass";
import { useProcessClasses } from "@/composables/process";
import { HierarchyTag } from "@/types/HierarchyTag";

const props = defineProps({
  splits: {
    type: Object as PropType<HierarchyTag["splits"]>,
    required: true,
  },
});

const emits = defineEmits(["updateSplits", "removeSplit"]);
const processClasses = useProcessClasses();

const tagSplits = ref<{ name: string; processes: ProcessClass[] }[]>([]);
const getProcessClassByEncodedLabel = processClasses.value.reduce((acc, processClass) => {
  acc[processClass.encodedLabel] = processClass;
  return acc;
}, {} as Record<EncodedLabel, ProcessClass>);

const buildingElements = Array.from(
  new Set(processClasses.value.map((process) => process.buildingElement)),
);

const buildingElementsOrder: BuildingElement[] = [
  "ground",
  "foundation",
  "ceiling",
  "wall",
  "roof",
];

const sortSplits = (splitsToSort: typeof tagSplits.value) => {
  const sortedSplits = splitsToSort.map((split) => {
    const processesToSort = [...split.processes];

    processesToSort.sort((a, b) => {
      return (
        buildingElementsOrder.indexOf(a.buildingElement) -
        buildingElementsOrder.indexOf(b.buildingElement)
      );
    });

    return {
      ...split,
      processes: processesToSort,
    };
  });

  return sortedSplits;
};

watch(
  () => props.splits,
  (value) => {
    if (!value) {
      return;
    }

    const remappedSplits = value.map((split) => {
      return {
        ...split,
        processes: split.processes.map((process) => getProcessClassByEncodedLabel[process]),
      };
    });
    tagSplits.value = sortSplits(remappedSplits);
  },
  { immediate: true },
);

const colorConfigBuildingElement: Record<BuildingElement, string> = {
  ground: "bg-orange-100 text-orange-700",
  foundation: "bg-gray-100 text-gray-700",
  ceiling: "bg-green-100 text-green-700",
  wall: "bg-blue-100 text-blue-700",
  roof: "bg-red-100 text-red-700",
};

const remapSplitsToOriginal = (splitsToRemap: typeof tagSplits.value) => {
  const remappedSplits = splitsToRemap.map((split) => {
    const encodedLabels = split.processes.map((process) => process.encodedLabel);
    encodedLabels.sort();

    return {
      ...split,
      processes: encodedLabels,
    };
  });

  return remappedSplits;
};

const selectLevelPreset = (buildingElement: BuildingElement, index: number) => {
  const processesForBuildingElement = processClasses.value.filter((process) => {
    return process.buildingElement === buildingElement;
  });

  const updatedSplits = tagSplits.value.map((split, splitIndex) => {
    const processesWithoutBuildingElement = split.processes.filter((process) => {
      return process.buildingElement !== buildingElement;
    });

    if (splitIndex === index) {
      const uniqueProcesses = [...processesWithoutBuildingElement, ...processesForBuildingElement];

      return {
        ...split,
        processes: Array.from(uniqueProcesses),
      };
    }

    return {
      ...split,
      processes: processesWithoutBuildingElement,
    };
  });

  tagSplits.value = sortSplits(updatedSplits);

  const remappedSplits = remapSplitsToOriginal(tagSplits.value);

  emits("updateSplits", remappedSplits);
};

const handleDnD = () => {
  tagSplits.value = sortSplits(tagSplits.value);

  const updatedSplits = remapSplitsToOriginal(tagSplits.value);

  emits("updateSplits", updatedSplits);
};

const handleNameChange = () => {
  const updatedSplits = remapSplitsToOriginal(tagSplits.value);

  emits("updateSplits", updatedSplits);
};
</script>
