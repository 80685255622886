import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import ValidationRepository from "@/repositories/ValidationRepository";
import { OpsReview, OpsReviewedProcessWithErrors } from "@/types/Validation";

export const useOpsReviewsForBatch = (batchId: Ref<string | undefined>) => {
  const { data, isLoading } = useQuery<OpsReview[]>({
    queryKey: ["ops-reviews", batchId],
    queryFn: () =>
      batchId.value ? ValidationRepository.loadOpsReviewsForBatch(batchId.value) : [],
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load ops reviews for batch");
      return false;
    },
  });

  return { opsReviewsForBatch: data, isLoading };
};

export const useOpsReviewedProcessesWithErrorsForBatches = (
  batchIds: Ref<string[] | undefined>,
) => {
  const { data, isLoading } = useQuery<OpsReviewedProcessWithErrors[]>({
    queryKey: ["ops-reviewed-processes-with-errors-for-batches", batchIds],
    queryFn: () =>
      batchIds.value && batchIds.value.length > 0
        ? ValidationRepository.loadOpsReviewedProcessesWithErrorsForBatches(batchIds.value)
        : [],
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load ops reviewed processes with errors for batches");
      return false;
    },
  });

  return { opsReviewedProcessesWithErrors: data, isLoading };
};
