<template>
  <dl class="divide-y divide-gray-200">
    <div v-for="(field, index) in projectConfigFields" :key="index">
      <div v-if="field.modal_field_type === 'switch'">
        <SwitchGroup as="div" class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
          <SwitchLabel as="dt" class="text-sm font-medium text-gray-700" passive>{{
            field.field_name_text
          }}</SwitchLabel>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="grow">{{ field.field_name_value }}</span>
            <Switch
              v-model="statusTrigger"
              :class="[
                projectStatus ? 'bg-green-400' : 'bg-red-400',
                'focus:outline-none relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:ml-auto',
              ]"
            >
              <span
                aria-hidden="true"
                :class="[
                  projectStatus ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
              />
            </Switch>
          </dd>
        </SwitchGroup>
      </div>
      <div v-else>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">{{ field.field_name_text }}</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="grow">{{ formatValue(field) }}</span>
            <span class="ml-4 shrink-0" v-if="!field.readonly">
              <button
                type="button"
                class="font-medium text-yellow-700 hover:text-yellow-600"
                @click="handleUpdateClick(field, index)"
              >
                Update
              </button>
            </span>
          </dd>
        </div>
      </div>
    </div>
  </dl>
  <!-- Delete project only for limited users -->
  <div class="py-4" v-if="this.$store.state.user.email.includes('tim.sippl@oculai.de')">
    <button
      class="focus:outline-none float-right inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      @click="
        removeModalOpen = true;
        currentObject = this.project;
      "
    >
      <TrashIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Projekt löschen
    </button>
  </div>

  <!-- Modals -->
  <EditModal ref="editModal" @reloadmetadata="getProjectMetaData"></EditModal>
  <RemoveModal
    v-bind:mode="'project'"
    ref="removeStreamModal"
    @reloadmetadata="getProjectMetaData"
  ></RemoveModal>
  <CountryCodeModal
    v-if="isCountryCodeModalOpen"
    :project="project"
    @close="isCountryCodeModalOpen = false"
    @update="handleCountryCodeUpdate"
  />
</template>

<script>
import { SwitchGroup, SwitchLabel, Switch } from "@headlessui/vue";
import { TrashIcon } from "@heroicons/vue/24/solid";
import { format, parseISO, isValid } from "date-fns";
import PlannerRepository from "shared/repositories/PlannerRepository";
import logger from "shared/services/logger";
import CountryCodeModal from "@/views/project_console/components/CountryCodeModal.vue";
import EditModal from "@/views/project_console/components/EditModal.vue";
import RemoveModal from "@/views/project_console/components/RemoveModal.vue";

export default {
  name: "EditProjectComponent",
  emits: ["reloadmetadata"],
  props: ["project"],
  watch: {
    project: {
      handler(newValue) {
        this.projectStatus = newValue.status === "active";

        // Field ID must correspond to key of json-response (project_name = project_name)
        this.projectConfigFields = [
          {
            field_name_text: "Projektname",
            field_name_value: this.project.name,
            modal_field_type: "text",
            modal_field_id: "name",
          },
          {
            field_name_text: "Kunde",
            field_name_value: this.project.customer_name,
            modal_field_type: "text",
            modal_field_id: "customer_name",
            readonly: true,
          },
          {
            field_name_text: "Site-ID",
            field_name_value: this.project.site_id,
            modal_field_type: "text",
            modal_field_id: "site_id",
            readonly: true,
          },
          {
            field_name_text: "Process Groups",
            field_name_value: this.project.process_groups,
            modal_field_type: "text",
            modal_field_id: "process_groups",
            readonly: true,
          },
          {
            field_name_text: "Länder Code/PLZ",
            field_name_value: `${this.project.country_code}/${this.project.zip_code}`,
            modal_field_type: "text",
            modal_field_id: "country_code_plz",
          },
          {
            field_name_text: "Zeitzone",
            field_name_value: this.project.timezone,
            modal_field_type: "select",
            modal_field_id: "timezone",
            options: [
              {
                value: "Europe/Berlin",
                text: "Europe/Berlin",
              },
              {
                value: "Europe/London",
                text: "Europe/London",
              },
            ],
          },
          {
            field_name_text: "Adresse",
            field_name_value: this.project.street,
            modal_field_type: "text",
            modal_field_id: "street",
          },
          {
            field_name_text: "Start Datum",
            field_name_value: this.project.planned_start,
            modal_field_type: "date",
            modal_field_id: "planned_start",
          },
          {
            field_name_text: "End Datum",
            field_name_value: this.project.planned_end,
            modal_field_type: "date",
            modal_field_id: "planned_end",
          },
          {
            field_name_text: "Projekt Status",
            field_name_value: this.project.status,
            modal_field_type: "switch",
            modal_field_id: "status",
          },
        ];
      },
      deep: true,
    },
    statusTrigger: function () {
      for (let i = 0; i < this.projectConfigFields.length; i++) {
        if (this.projectConfigFields[i]["modal_field_id"] === "status") {
          this.fieldIdx = i;
        }
      }
      this.projectStatus = !this.projectStatus;

      if (this.projectStatus) {
        this.switchStatus = "active";
      } else {
        this.switchStatus = "completed";
      }

      this.$refs.editModal.updateField("status", "switch");

      if (
        this.switchStatus === "completed" &&
        this.project.features.some((item) => item === "planner")
      ) {
        if (
          window.confirm(
            `Planner Tracking: Do you also want to additionally set the end date for all active planner items to the timestamp of the last tracked process? (recommended)`,
          )
        ) {
          PlannerRepository.updateActualEventsToEndTracking(
            this.project.customer_name,
            this.project.site_id,
          )
            .then((data) => {
              if (data.number_changes === 0) {
                this.showToast("success", `No active planner items, nothing to update!`);
              } else {
                this.showToast(
                  "success",
                  `Success, set end date for ${data.number_changes} planner items to: ${format(
                    parseISO(data.end_time),
                    "dd.MM.yyyy (HH:mm)",
                  )}`,
                );
              }
            })
            .catch((error) => {
              logger.error(error);
              this.showToast("warning", "Unable to terminate planner tracking");
            });
          return;
        }
      }
    },
  },
  data() {
    return {
      editModalOpen: false,
      removeModalOpen: false,
      model: "project",
      modalFieldText: "",
      modalFieldValue: "",
      modalFieldId: "",
      modalFieldType: "",
      currentObject: {},
      fieldIdx: 0,
      projectConfigFields: [],
      statusTrigger: false,
      projectStatus: false,
      switchStatus: "",
      isCountryCodeModalOpen: false,
    };
  },
  components: {
    CountryCodeModal,
    SwitchGroup,
    SwitchLabel,
    Switch,
    EditModal,
    RemoveModal,
    TrashIcon,
  },
  methods: {
    handleUpdateClick(field, index) {
      this.fieldIdx = index;
      this.modalFieldType = field.modal_field_type;
      this.modalFieldText = field.field_name_text;
      this.modalFieldValue = field.field_name_value;
      this.modalFieldId = field.modal_field_id;
      this.modalFieldOptions = field.options;
      this.isCountryCodeModalOpen = field.modal_field_id === "country_code_plz";
      this.editModalOpen = !this.isCountryCodeModalOpen;
    },
    getProjectMetaData(project_selector) {
      this.$emit("reloadmetadata", project_selector);
    },
    formatValue(field) {
      if (Array.isArray(field.field_name_value)) {
        return field.field_name_value.join(", ");
      }
      if (field.modal_field_type === "date") {
        return isValid(field.field_name_value) ? format(field.field_name_value, "dd.MM.yyyy") : "-";
      }

      return field.field_name_value;
    },
    handleCountryCodeUpdate(updatedProject) {
      const project = this.project;
      project.country_code = updatedProject.country_code;
      project.zip_code = updatedProject.zip_code;
      this.isCountryCodeModalOpen = false;
    },
  },
};
</script>
