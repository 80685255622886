<template>
  <!-- Edit Modal -->
  <TransitionRoot as="template" :show="this.$parent.editModalOpen">
    <Dialog as="div" class="relative z-10" @close="this.$parent.editModalOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-white rounded-lg px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-2"
            >
              <div class="px-2 py-4">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ this.$parent.modalFieldText }}:
                </h3>
                <!-- Select Input Analytics -->
                <div
                  v-if="
                    this.$parent.modalFieldType === 'select' && this.$parent.model === 'analytics'
                  "
                >
                  <div class="pt-2" v-show="this.$parent.modalFieldValue === 'active'">
                    <div class="rounded-md bg-red-50 p-3">
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                          Bei Umstellung auf "disabled" wird die ausgewählte Feature Permission für
                          alle Teilnehmer des Projekts auf inaktiv gesetzt!
                        </h3>
                      </div>
                    </div>
                  </div>
                  <form
                    @submit.prevent="updateField(this.$parent.modalFieldId, 'select')"
                    class="mt-5 sm:flex sm:items-center"
                  >
                    <div class="w-full sm:max-w-xs">
                      <select
                        :name="this.$parent.modalFieldId"
                        :id="this.$parent.modalFieldId"
                        class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:max-w-xs sm:text-sm"
                      >
                        <option :selected="'active' === this.$parent.modalFieldValue">
                          active
                        </option>
                        <option :selected="'disabled' === this.$parent.modalFieldValue">
                          disabled
                        </option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      class="focus:outline-none mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-1 focus:ring-yellow-300 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Save
                    </button>
                  </form>
                </div>
                <div v-else-if="this.$parent.modalFieldType === 'boolean'">
                  <form
                    @submit.prevent="updateField(this.$parent.modalFieldId, 'select')"
                    class="mt-5 sm:flex sm:items-center"
                  >
                    <div class="w-full sm:max-w-xs">
                      <select
                        :name="this.$parent.modalFieldId"
                        :id="this.$parent.modalFieldId"
                        class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:max-w-xs sm:text-sm"
                        v-on:change="setBooleanValue"
                      >
                        <option value="0" :selected="!this.$parent.modalFieldValue">Off</option>
                        <option value="1" :selected="this.$parent.modalFieldValue">On</option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      class="focus:outline-none mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-1 focus:ring-yellow-300 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Save
                    </button>
                  </form>
                </div>
                <div v-else-if="this.$parent.modalFieldType === 'select'">
                  <form
                    @submit.prevent="updateField(this.$parent.modalFieldId, 'select')"
                    class="mt-5 sm:flex sm:items-center"
                  >
                    <div class="w-full sm:max-w-xs">
                      <select
                        :name="this.$parent.modalFieldId"
                        :id="this.$parent.modalFieldId"
                        class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:max-w-xs sm:text-sm"
                        v-on:change="setSelectValue"
                      >
                        <option
                          v-for="option in this.$parent.modalFieldOptions.filter(
                            (option) => !option.hidden,
                          )"
                          :key="option"
                          :value="option.value"
                          :selected="valueIsEqual(this.$parent.modalFieldValue, option.value)"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      class="focus:outline-none mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-1 focus:ring-yellow-300 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Save
                    </button>
                  </form>
                </div>
                <div v-else-if="this.$parent.modalFieldType === 'date'">
                  <form
                    @submit.prevent="updateField(this.$parent.modalFieldId, 'field')"
                    class="mt-5 sm:flex sm:items-center"
                  >
                    <div class="w-full sm:max-w-xs">
                      <input
                        :type="this.$parent.modalFieldType"
                        :name="this.$parent.modalFieldId"
                        :id="this.$parent.modalFieldId"
                        :value="formatDateValue(this.$parent.modalFieldValue)"
                        @change="handleDateValueChange"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
                      />
                    </div>
                    <button
                      type="submit"
                      class="focus:outline-none mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-1 focus:ring-yellow-300 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Save
                    </button>
                  </form>
                </div>
                <!--Other Input Types (Text) -->
                <div v-else>
                  <form
                    @submit.prevent="updateField(this.$parent.modalFieldId, 'field')"
                    class="mt-5 sm:flex sm:items-center"
                  >
                    <div class="w-full sm:max-w-xs">
                      <input
                        :type="this.$parent.modalFieldType"
                        :name="this.$parent.modalFieldId"
                        :id="this.$parent.modalFieldId"
                        :value="this.$parent.modalFieldValue"
                        v-model="this.$parent.updatedValue"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
                      />
                    </div>
                    <button
                      type="submit"
                      class="focus:outline-none mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-1 focus:ring-yellow-300 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      Save
                    </button>
                  </form>
                </div>
                <div class="text-red mt-1" v-if="error">{{ error }}</div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { format, parse, startOfDay, isValid } from "date-fns";
import logger from "shared/services/logger";
import ProjectRepository from "@/repositories/ProjectRepository";
import StreamRepository from "@/repositories/StreamRepository";
import { cameraPresets } from "@/views/project_console/projectConsole";

export default {
  name: "EditModal",
  data() {
    return {
      selectValue: "",
      error: null,
    };
  },
  components: {
    DialogPanel,
    Dialog,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    updateField(field_name, source) {
      if (this.$parent.model === "analytics") {
        throw new Error("Not supported");
      }

      // Check for switch type
      let updated_value;
      if (source === "switch") {
        updated_value = this.$parent.switchStatus;
      } else if (source === "select") {
        updated_value = this.selectValue;
      } else {
        updated_value = this.$parent.updatedValue;
      }

      if (this.$parent.model === "project") {
        const updatedProject = {
          [field_name]: updated_value,
        };

        if (field_name === "planned_end" && !isValid(updated_value)) {
          updatedProject["planned_end"] = null;
        }

        if (
          field_name === "status" &&
          updated_value === "completed" &&
          !this.$parent.project.planned_end
        ) {
          updatedProject["planned_end"] = startOfDay(new Date());
        }

        const fullUpdatedProject = {
          ...this.$parent.project,
          ...updatedProject,
        };

        if (field_name === "planned_start" && !isValid(updated_value)) {
          this.error = "Ungültiges Datum";
          return;
        }

        if (
          fullUpdatedProject.planned_end &&
          fullUpdatedProject.planned_start >= fullUpdatedProject.planned_end
        ) {
          this.error = "Projektbeginn sollte vor dem Ende liegen";
          return;
        }

        ProjectRepository.updateProject(
          this.$parent.project.customer_name,
          this.$parent.project.site_id,
          updatedProject,
        )
          .then(() => {
            // Update local fields
            this.updateFields(updated_value, field_name);
            if (field_name === "status") {
              this.$emit("reloadmetadata", this.$parent.project);
            }
            this.$parent.editModalOpen = false;
          })
          .catch((error) => {
            logger.error(error);
            alert("Unable to save edit");
          });
      } else {
        const stream = this.$parent.project.streams.find(
          (s) => s._id === this.$parent.cameraIdentifier,
        );
        const [stream_updated_value, streamToUpdate] = this.getStreamToUpdate(
          stream,
          field_name,
          updated_value,
        );
        StreamRepository.updateStream(
          stream.customer_name,
          stream.site_id,
          stream.camera_id,
          streamToUpdate,
        )
          .then(() => {
            Object.entries(streamToUpdate).forEach(([key, value]) => {
              this.updateFields(value, key);
            });
            this.updateFields(stream_updated_value, field_name);
            this.$parent.editModalOpen = false;
          })
          .catch((error) => {
            logger.error(error);
          });
      }
    },
    getStreamToUpdate(stream, field_name, updated_value) {
      if (field_name === "image_weekdays") {
        const value = updated_value.split(",");
        return [value, { image_weekdays: value }];
      }

      if (field_name === "camera_preset") {
        const preset = cameraPresets[updated_value];
        if (preset) {
          const streamToUpdate = {
            ...preset,
          };
          return [updated_value, streamToUpdate];
        }
        throw new Error(`Not supported camera preset ${updated_value}`);
      }

      return [updated_value, { [field_name]: updated_value }];
    },
    updateFields(updated_value, field_name) {
      if (this.$parent.model === "project") {
        this.$parent.projectConfigFields[this.$parent.fieldIdx]["field_name_value"] = updated_value;
        this.$parent.project[field_name] = updated_value;
      }
      if (this.$parent.model === "stream") {
        this.$parent.project["streams"][this.$parent.streamIdx][field_name] = updated_value;
      }
      if (this.$parent.model === "analytics") {
        this.$parent.features[this.$parent.featureIdx]["properties"][this.$parent.fieldIdx][
          "field_name_value"
        ] = updated_value;
        this.$parent.project["features"][this.$parent.featureIdx][field_name] = updated_value;
      }
    },
    setSelectValue: function (event) {
      // update model value for later update triggered through button click
      this.selectValue = event.target.value;
    },
    setBooleanValue: function (event) {
      this.selectValue = event.target.value === "1";
    },
    formatDateValue(value) {
      return value ? format(value, "yyyy-MM-dd") : value;
    },
    handleDateValueChange(event) {
      this.$parent.updatedValue = parse(event.target.value, "yyyy-MM-dd", new Date());
      this.error = null;
    },
    valueIsEqual(value1, value2) {
      if (Array.isArray(value1) && Array.isArray(value2)) {
        return JSON.stringify(value1) === JSON.stringify(value2);
      }
      return value1 === value2;
    },
  },
  watch: {
    "$parent.editModalOpen"() {
      this.error = null;
      if (this.$parent.editModalOpen) {
        this.$parent.updatedValue = this.$parent.modalFieldValue;
        if (this.$parent.modalFieldType === "select") {
          const option = this.$parent.modalFieldOptions.find(
            ({ value, hidden }) =>
              !hidden && this.valueIsEqual(value, this.$parent.modalFieldValue),
          );
          const selectedOption = option ?? this.$parent.modalFieldOptions[0];
          this.selectValue = (selectedOption?.value || "").toString();
        }
      }
    },
  },
};
</script>
