import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import PlannerRepository from "shared/repositories/PlannerRepository";
import logger from "shared/services/logger";
import { ProjectPlannerStat, ReadyToCloseActualEvent } from "shared/types/Plan";
import SectionMasksRepository from "@/repositories/SectionMasksRepository";
import { SectionMask } from "@/types/HierarchyTag";

export const useProjectPlannerStats = () => {
  const {
    data: projectPlannerStats,
    isLoading,
    error,
  } = useQuery<ProjectPlannerStat[] | undefined>({
    queryKey: ["project-planner-stats"],
    queryFn: () => PlannerRepository.loadProjectPlannerStats(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load project planner stats");
      return false;
    },
  });

  return { projectPlannerStats, isLoading, error };
};

export const useActualEventsReadyToClose = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const {
    data: actualEventsReadyToClose,
    isLoading,
    error,
  } = useQuery<ReadyToCloseActualEvent[] | undefined>({
    queryKey: ["actual-events-ready-to-close", customerName, siteId],
    queryFn: () => PlannerRepository.loadActualEventsReadyToClose(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load actual events ready to close");
      return false;
    },
  });

  return { actualEventsReadyToClose, isLoading, error };
};

export const useSectionMasks = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const {
    data: sectionMasks,
    isLoading,
    error,
  } = useQuery<SectionMask[]>({
    queryKey: ["section-masks", customerName, siteId],
    queryFn: () => SectionMasksRepository.loadSectionMasks(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load section masks");
      return false;
    },
  });

  return { sectionMasks, isLoading, error };
};

export const useDeferReadyToClose = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const {
    mutateAsync: deferReadyToClose,
    isLoading: isLoading,
    error,
  } = useMutation<ProjectPlannerStat, Error, string>({
    mutationFn: (actualEventId) =>
      PlannerRepository.deferReadyToClose(customerName, siteId, actualEventId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to defer actual event");
      return false;
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["project-planner-stats"],
        (stats: ProjectPlannerStat[] | undefined) =>
          stats?.map((stat) => (stat._id === result._id ? result : stat)),
      );
    },
  });

  return { deferReadyToClose, isLoading, error };
};
