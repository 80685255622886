<template>
  <div class="flex flex-col gap-1">
    <div class="flex gap-1 cursor-pointer" @click="toggleChange(change._id)">
      <input type="checkbox" :checked="selectedChangeIds.has(change._id)" />
      <div>
        {{ change.plannerItem.name }}
      </div>
    </div>
    <div v-for="childChange in change.children" :key="childChange._id" class="ml-5">
      <ImportPlanPlannerItem
        :selectedChangeIds="selectedChangeIds"
        :change="childChange"
        @setChange="$emit('setChange', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PlannerItem } from "shared/types/Plan";
import { HierarchicalChange } from "@/views/planner/components/import/ImportPlanMixins";

export default defineComponent({
  name: "ImportPlanPlannerItem",
  props: {
    selectedChangeIds: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
    change: {
      type: Object as PropType<HierarchicalChange<PlannerItem | null>>,
      required: true,
    },
  },
  emits: ["setChange"],
  methods: {
    toggleChange(changeId: string) {
      this.$emit("setChange", { changeId, selected: !this.selectedChangeIds.has(changeId) });
    },
  },
});
</script>
