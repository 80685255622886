import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import logger from "shared/services/logger";
import { Sequence } from "@/views/person_gad/types";
import SequenceRepository from "../../repositories/SequenceRepository";

export const usePersonGadSequences = (query: Ref<URLSearchParams>) => {
  const queryKey = computed(() => query.value.toString());

  const { data, isLoading, error } = useQuery<Sequence[]>({
    queryKey: ["sequences", queryKey],
    queryFn: async () => {
      return await SequenceRepository.loadSequences(queryKey.value);
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const sequences = computed(() => data.value || []);

  return { sequences, isLoading, error };
};
