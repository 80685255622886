import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import {
  UnitValue,
  UnitValueAggregate,
  UnitValueAggregateByProject,
  UnitValueAggregateParameters,
  UnitValueType,
} from "@/types/UnitValue";

const loadUnitValueAggregates = (): Promise<UnitValueAggregate[]> =>
  apiClient
    .get<void, AxiosResponse<UnitValueAggregate[]>>(`/unit_values/aggregates`)
    .then((response) => response.data);

const loadUnitValueAggregatesByProject = (): Promise<UnitValueAggregateByProject[]> =>
  apiClient
    .get<void, AxiosResponse<UnitValueAggregateByProject[]>>(`/unit_values/aggregates/by-project`)
    .then((response) => response.data);

const calculateUnitValueAggregates = async (
  parameters?: UnitValueAggregateParameters,
): Promise<UnitValueAggregate[]> => {
  const query = new URLSearchParams();
  if (parameters) {
    if (parameters.confidenceAlpha !== null) {
      query.set("confidence_alpha", parameters.confidenceAlpha.toString());
    }
    if (parameters.tolerancePercentile !== null) {
      query.set("tolerance_percentile", parameters.tolerancePercentile.toString());
    }
    if (parameters.toleranceAlpha !== null) {
      query.set("tolerance_alpha", parameters.toleranceAlpha.toString());
    }
  }
  return await apiClient
    .post<void, AxiosResponse<UnitValueAggregate[]>>(
      `/unit_values/aggregates/calculate?${query.toString()}`,
    )
    .then((response) => response.data);
};

const loadUnitValuesByType = (type: UnitValueType): Promise<UnitValue[]> =>
  apiClient
    .get<void, AxiosResponse<UnitValue[]>>(`/unit_values/by-type/${encodeURIComponent(type)}`)
    .then((response) => response.data);

export default {
  loadUnitValueAggregates,
  loadUnitValueAggregatesByProject,
  calculateUnitValueAggregates,
  loadUnitValuesByType,
};
