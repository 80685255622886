import { createStore } from "vuex";
import { Project } from "shared/types/Project";
import { ConfirmationModalContext } from "@/types/Modals";
import { User } from "@/types/User";

export default createStore({
  state: {
    user: undefined as User | undefined,
    userLoaded: false,
    conflictingProcessesCount: null as number | null,
    offlineStreamCount: null as number | null,
    projects: [] as Project[],
    confirmationModal: undefined as ConfirmationModalContext | undefined,
    isSessionExpired: false,
    sessionStartDate: null as Date | null,
    sessionTimer: null as ReturnType<typeof setInterval> | null,
  },
  mutations: {
    setUser(state, user: User | undefined) {
      state.user = user;
      state.userLoaded = true;
    },
    setConflictingProcessesCount(state, count) {
      state.conflictingProcessesCount = count;
    },
    setOfflineStreamCount(state, count) {
      state.offlineStreamCount = count;
    },
    setProjects(state, projects: Project[]) {
      state.projects = projects;
    },
    setConfirmationModalArgs(state, confirmationModalArgs) {
      state.confirmationModal = {
        args: confirmationModalArgs,
        confirmed: null,
      };
    },
    setConfirmationModalConfirmed(state, confirmed) {
      if (!state.confirmationModal) {
        throw new Error("Confirmation modal not initialized");
      }
      state.confirmationModal.confirmed = confirmed;
    },
    clearConfirmationModal(state) {
      state.confirmationModal = undefined;
    },
    setIsSessionExpired(state, newIsSessionExpired) {
      state.isSessionExpired = newIsSessionExpired;
    },
    setSessionStartDate(state, newSessionStartDate) {
      state.sessionStartDate = newSessionStartDate;
    },
    setSessionTimer(state, newSessionTimer: ReturnType<typeof setInterval> | null) {
      state.sessionTimer = newSessionTimer;
    },
  },
});
