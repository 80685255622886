<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-5">
    <template #content>
      <div class="h-[90vh] flex flex-col text-left">
        <div v-if="isLoading" class="flex items-center justify-center flex-1">
          <LoadingSpinner />
        </div>
        <div v-else class="flex-1 flex flex-col overflow-auto pr-4 -mr-4">
          <div
            class="flex flex-col gap-2"
            v-if="actualEvents && actualEvents.length > 0 && planConfig"
          >
            <div class="flex justify-between items-center pl-2 border-b pb-2">
              <div>
                {{ currentCustomerName }}/{{ currentSiteId }} | total: {{ actualEvents.length }}
              </div>
              <RouterLink
                v-if="!hidePlannerLink"
                :to="{
                  name: 'Planner',
                  params: {
                    customer_name: currentCustomerName,
                    site_id: currentSiteId,
                  },
                  query: {
                    mode: 'tracker',
                  },
                }"
                class="flex gap-1 items-center text-md"
                target="_blank"
              >
                <ArrowTopRightOnSquareIcon class="w-5 h-5" style="margin-top: -2px" />
                Planner
              </RouterLink>
            </div>
            <div class="flex flex-col gap-1">
              <div class="font-semibold grid" :style="{ gridTemplateColumns: '1fr 120px' }">
                <div class="px-2 py-0.5">Planner item</div>
                <div class="px-2 py-0.5 text-right">Inactive days</div>
              </div>
              <ReadyToCloseModalItem
                v-for="event in actualEvents"
                :key="event._id"
                :actualEvent="event"
                :planConfig="planConfig"
                :readyToCloseActualEvent="readyToCloseById[event._id]"
                :sourceIdsToNames="sourceIdsToNames"
                :shortenedProcessesWithTags="shortenedProcessesWithTags"
                :hierarchyTags="hierarchyTags"
              />
            </div>
          </div>
          <div v-else class="flex items-center justify-center flex-1">No events found</div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { usePlanConfig } from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import PlanMixins from "shared/mixins/PlanMixins";
import { ReadyToCloseActualEvent } from "shared/types/Plan";
import { useActualEventsReadyToClose, useProjectPlannerStats } from "@/composables/planner";
import ReadyToCloseModalItem from "@/views/planner/components/ReadyToCloseModalItem.vue";

defineProps<{ hidePlannerLink?: boolean }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const { actualEventsReadyToClose, isLoading: areActualEventsReadyToCloseLoading } =
  useActualEventsReadyToClose();
const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();
const { shortenedProcessesWithTags } = useShortenedProcessesWithTags();
const { projectPlannerStats, isLoading: areProjectPlannerStatsLoading } = useProjectPlannerStats();

const deferredActualEventIds = computed(() => {
  const stat = projectPlannerStats.value?.find(
    (item) => item.customer_name === currentCustomerName && item.site_id === currentSiteId,
  );
  if (stat) {
    return new Set<string>(
      stat.ready_to_close.filter((item) => item.defer_until).map((item) => item._id),
    );
  }
  return new Set<string>();
});

const actualEvents = computed(
  () =>
    planConfig.value?.actual_events.filter(
      (event) => readyToCloseById.value[event._id] && !deferredActualEventIds.value.has(event._id),
    ) || [],
);

const isLoading = computed(
  () =>
    areActualEventsReadyToCloseLoading.value ||
    isPlanConfigLoading.value ||
    areProjectPlannerStatsLoading.value,
);

const readyToCloseById = computed(() =>
  (actualEventsReadyToClose.value || []).reduce((acc, event) => {
    acc[event._id] = event;
    return acc;
  }, {} as Record<string, ReadyToCloseActualEvent>),
);

const sourceIdsToNames = computed(() =>
  planConfig.value
    ? PlanMixins.methods?.getSourceIdsToNames(planConfig.value.planner_items)
    : undefined,
);

const { hierarchyTags } = useHierarchyTags();
</script>
