import { useStore } from "vuex";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { Project } from "shared/types/Project";

export const useCurrentProject = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const store = useStore();
  const projects = store.state.projects as Project[] | undefined;

  return (projects &&
    projects.find(
      (project) => project.customer_name === customerName && project.site_id === siteId,
    )) as Project;
};

export const useProjects = () => {
  const store = useStore();
  return store.state.projects as Project[];
};
