import { useQuery } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { computed, Ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import getProcessClasses from "shared/constants/ProcessClasses";
import logger from "shared/services/logger";
import { ProcessClass } from "shared/types/ProcessClass";
import { useCurrentProject } from "@/composables/project";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";

export const useProcessClasses = () => {
  const currentProject = useCurrentProject();

  return computed(() => {
    const processGroups = currentProject ? currentProject.process_groups : null;
    return getProcessClasses(processGroups) as ProcessClass[];
  });
};

export const useProcessVideoUrl = (processDbId: Ref<string | undefined>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const {
    data: processVideoUrl,
    isLoading,
    error,
  } = useQuery<string>({
    queryKey: ["process-video-url", customerName, siteId, processDbId],
    queryFn: async () => {
      if (!processDbId.value) {
        return "";
      }
      const { url } = await OpsProcessesRepository.loadProcessVideoUrl(
        customerName,
        siteId,
        processDbId.value,
      );
      return url;
    },
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error("Unable to load process video url");
      }
      return false;
    },
    staleTime: 2 * 60 * 60,
  });

  return { processVideoUrl, isLoading, error };
};
