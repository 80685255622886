<template>
  <Modal :open="open" @close="$emit('cancelEdit')" customCls="sm:w-1/3">
    <template #title>
      <p class="flex items-center">{{ eventRecord.name }} - {{ eventRecord.type }}</p>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div class="flex gap-5">
        <div class="flex items-center flex-1 py-3" v-if="eventRecord.type === 'actual'">
          <OaiSwitch
            v-model="isComplete"
            @changed="handleIsCompleteChanged"
            :disabled="isMilestone"
          />
          <div
            class="ml-3 text-sm font-medium"
            :class="[isMilestone ? 'text-gray-300' : 'text-gray-900']"
          >
            {{ isComplete ? "Completed" : "In Progress" }}
          </div>
        </div>
        <div class="flex items-center flex-1 py-3 cursor-pointer" @click="toggleMilestone">
          <input
            type="checkbox"
            :checked="isMilestone"
            class="h-4 w-4 rounded border-gray-300 text-gray-600"
          />
          <div class="ml-3 text-sm font-medium text-gray-900">Milestone</div>
        </div>
      </div>
      <div>
        <DateTime
          name="startDate"
          :value="startDate"
          @changed="startDate = $event"
          :timeDisabled="timeDisabled"
          dateLabel="Start Date"
          timeLabel="Start Time"
          cls="border-b py-3"
          :maxDate="maxDate"
        />
        <DateTime
          name="endDate"
          :value="endDate"
          @changed="endDate = $event"
          :disabled="(!isComplete && eventRecord.type === 'actual') || isMilestone"
          :timeDisabled="timeDisabled"
          dateLabel="End Date"
          timeLabel="End Time"
          cls="border-b py-3"
          :maxDate="maxDate"
        />
      </div>
      <div v-if="validationErrorMessage" class="py-2">
        <small class="text-red-500">{{ validationErrorMessage }}</small>
      </div>
      <div class="flex justify-between pt-4">
        <button
          @click="$emit('deleteEvent')"
          type="button"
          class="inline-flex items-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Delete
        </button>
        <div>
          <button
            @click="$emit('cancelEdit')"
            type="button"
            class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <span class="px-1"></span>
          <button
            @click="saveEvent()"
            type="button"
            :class="{ error: 'bg-gray-100' }"
            class="inline-flex items-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            :disabled="!!validationErrorMessage"
          >
            Save
          </button>
        </div>
      </div>
    </template>
    DateTime
  </Modal>
</template>

<script lang="ts">
import { set, subDays, isMonday } from "date-fns";
import { defineComponent, PropType } from "vue";
import Modal from "shared/components/modals/Modal.vue";
import OaiSwitch from "shared/components/other/OaiSwitch.vue";
import PlanMixins from "shared/mixins/PlanMixins";
import { EventToEdit } from "shared/types/Plan";
import DateTime from "@/views/planner/components/DateTime.vue";

export default defineComponent({
  name: "EventEditor",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    eventRecord: {
      type: Object as PropType<EventToEdit>,
      required: true,
    },
    now: {
      type: Date,
      required: true,
    },
  },
  emits: ["saveEvent", "deleteEvent", "cancelEdit"],
  components: {
    Modal,
    DateTime,
    OaiSwitch,
  },
  data() {
    return {
      startDate: null as Date | null,
      endDate: null as Date | null,
      isComplete: false as boolean,
      timeDisabled: true as boolean,
      isMilestone: false as boolean,
    };
  },
  mixins: [PlanMixins],
  computed: {
    isActual() {
      return this.eventRecord.type === "actual";
    },
    maxDate() {
      return this.eventRecord.type === "actual" ? new Date() : undefined;
    },
    validationErrorMessage() {
      return this.eventRecord.type === "actual"
        ? this.getStartEndDateValidationMessageForActualEvent(
            this.startDate,
            this.endDate,
            this.now,
            this.isMilestone,
          )
        : this.getStartEndDateValidationMessageForPlannedEvent(
            this.startDate,
            this.endDate,
            this.isMilestone,
          );
    },
  },
  watch: {
    open() {
      this.initialize();
    },
    startDate() {
      if (this.isMilestone) {
        this.endDate = this.startDate;
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    getDefaultPreviousDayAt5pm() {
      return set(subDays(this.now, isMonday(this.now) ? 2 : 1), {
        hours: 17,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
    },
    initialize() {
      if (this.eventRecord && this.open) {
        this.startDate = this.eventRecord.startDate;
        this.endDate = this.eventRecord.endDate;
        this.isComplete = !this.eventRecord.active;
        this.isMilestone =
          this.eventRecord.startDate?.getTime() === this.eventRecord.endDate?.getTime();
      }
    },
    getComputedEndDate() {
      return this.isComplete
        ? this.eventRecord.active || this.eventRecord.type === "planned"
          ? this.getDefaultPreviousDayAt5pm()
          : this.eventRecord.endDate
        : this.now;
    },
    handleIsCompleteChanged() {
      this.endDate = this.getComputedEndDate();
    },
    saveEvent() {
      if (this.validationErrorMessage) {
        return;
      }
      this.$emit("saveEvent", this.startDate, this.endDate, !this.isComplete);
    },
    toggleMilestone() {
      this.isMilestone = !this.isMilestone;
      this.endDate = this.isMilestone ? this.startDate : this.getComputedEndDate();
    },
  },
});
</script>
