<template>
  <div @keyup.esc="emit('closed')">
    <div class="fixed inset-0 bg-gray-500/75" style="z-index: 9999" />
    <div
      class="fixed inset-0 flex items-start lg:items-center justify-center"
      style="z-index: 10000"
      @click="emit('closed')"
    >
      <div class="p-3 flex gap-1 flex-col text-gray-700 container" @click.stop>
        <input
          ref="searchInput"
          autofocus
          autocomplete="off"
          class="px-3 py-2 w-full rounded border border-gray-300 focus:border-gray-300 outline-none placeholder-gray-300"
          placeholder="search for project, customer name or site id..."
          v-model="searchValue"
          @keydown="handleInputKeyDown"
        />
        <div class="bg-white border border-gray-300 overflow-auto py-1" style="height: 300px">
          <div
            :id="`quick-search-project-${project._id}`"
            v-for="project in projects"
            :key="project._id"
            class="flex gap-1 cursor-pointer px-3 py-1.5 text-sm hover:bg-gray-200"
            :class="selectedProject?._id === project._id ? ['bg-gray-100'] : []"
            @click="goToProject(project)"
          >
            <div class="flex gap-1 items-center flex-1 min-w-0">
              <div class="truncate">{{ project.name }}</div>
              <div class="text-gray-400">{{ project.customer_name }}</div>
              <div class="text-gray-400">{{ project.site_id }}</div>
            </div>
            <div class="gap-1 items-center hidden lg:flex">
              <div class="text-gray-300 font-mono text-xs" style="width: 200px">
                {{ format(project.planned_start, "dd.MM.yyyy") }}
                {{ project.planned_end ? `- ${format(project.planned_end, "dd.MM.yyyy")}` : "" }}
              </div>
              <div
                class="text-right"
                :class="project.status === 'active' ? ['text-green-400'] : ['text-blue-400']"
                style="width: 80px"
              >
                {{ project.status }}
              </div>
            </div>
          </div>
          <div v-if="projects.length === 0" class="px-3 py-1.5 text-sm">No projects found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import FuzzySearch from "fuzzy-search";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Project } from "shared/types/Project";

const searchValue = ref("");
const selectedProject = ref<Project | null>(null);
const searchInput = ref<HTMLInputElement | null>(null);

const store = useStore();
const router = useRouter();

const emit = defineEmits(["closed"]);

const normalize = (text: string) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll("ß", "ss");

const normalizedProjects = computed(
  () =>
    store.state.projects.map((project: Project) => ({
      ...project,
      normalized_name: normalize(project.name),
      normalized_customer_name: normalize(project.customer_name),
      normalized_site_id: normalize(project.site_id),
    })) as Project[],
);

const projects = computed(() => {
  if (!searchValue.value) {
    return normalizedProjects.value;
  }

  const searchItems = normalize(searchValue.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item);

  return searchItems.reduce((acc, search) => {
    const searcher = new FuzzySearch(
      acc,
      ["normalized_name", "normalized_customer_name", "normalized_site_id"],
      {
        caseSensitive: false,
        sort: false,
      },
    );
    return searcher.search(search);
  }, normalizedProjects.value);
});

watch(
  () => projects.value,
  () => {
    selectProject(projects.value[0]);
  },
);

onMounted(() => {
  searchValue.value = "";
  setTimeout(() => {
    searchInput.value?.focus();
    selectProject(projects.value[0]);
  }, 50);
});

const selectProject = (project: Project) => {
  selectedProject.value = project;
  if (project) {
    document.getElementById(`quick-search-project-${project._id}`)?.scrollIntoView({
      block: "center",
    });
  }
};

const goToProject = (project: Project) => {
  router.push(`/project-console?customer_name=${project.customer_name}&site_id=${project.site_id}`);
  emit("closed");
};

const moveUp = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) - 1]) ||
    projects.value[projects.value.length - 1];
  selectProject(project);
};

const moveDown = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) + 1]) ||
    projects.value[0];
  selectProject(project);
};

const goToSelectedProject = () => {
  if (selectedProject.value) {
    goToProject(selectedProject.value);
  }
};

const handleInputKeyDown = (event: KeyboardEvent) => {
  const handledKeys = ["ArrowDown", "ArrowUp", "Enter"];
  if (handledKeys.includes(event.key)) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "ArrowUp") {
      moveUp();
    }
    if (event.key === "ArrowDown") {
      moveDown();
    }
    if (event.key === "Enter") {
      goToSelectedProject();
    }

    return false;
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: none;
}

@media (min-width: 1024px) {
  .container {
    width: 75%;
  }
}
</style>
