import { v4 as uuid } from "uuid";
import { markRaw, ref } from "vue";
import getProcessClasses from "shared/constants/ProcessClasses";
import { DecodedLabel } from "shared/types/ProcessClass";
import SequenceRepository from "@/repositories/SequenceRepository";
import { Sequence, SequenceExport } from "@/views/person_gad/types";
import { Annotation } from "../Annotation";
import { GROUP_COLORS, GroupColor } from "../colors";
import CanvasService from "./canvasService";

export type GroupProcessClasses = DecodedLabel | "no_class";

export type AnnotationGroup = {
  id: string;
  groupId: number;
  processClass: GroupProcessClasses;
  color: GroupColor;
  annotations: Annotation[];
};

type SequenceGroup = Sequence["groups"][number] & { visible: boolean };

const processClasses = getProcessClasses();

export default class GroupService {
  MAX_FRAMES_NUMBER = 15;

  private cleanListeners: (() => void)[] = [];
  private cleanSaveInterval = () => {};
  private _frameIndex = ref(0);
  private readonly _sequenceGroups = ref<SequenceGroup[]>([]);
  private readonly allFrameGroups = ref<AnnotationGroup[][]>([]);
  private readonly canvasService: CanvasService;
  private readonly _sequence = ref<Sequence>({} as Sequence);

  constructor(canvasService: CanvasService, sequence: Sequence) {
    this.canvasService = canvasService;
    this._sequence.value = sequence;
    this._sequenceGroups.value = sequence.groups.map((group) => ({ ...group, visible: true }));

    this.initFrameGroups(sequence);
    this.renderPctBboxes();
    this.setFrameIndex(0);
    this.initAutoSave();

    this.canvasService.on("annotation:created", this._handleAnnotationCreation.bind(this));
    this.canvasService.on("annotation:deleted", this._removeAnnotationsFromGroups.bind(this));
  }

  get currentFrameGroups() {
    return this.allFrameGroups.value[this._frameIndex.value] as AnnotationGroup[];
  }

  get sequenceGroups() {
    return this._sequenceGroups.value as SequenceGroup[];
  }

  get frameIndex() {
    return this._frameIndex.value;
  }

  get sequence() {
    return this._sequence.value;
  }

  private _generateId() {
    return uuid();
  }

  private _getSequenceGroupByGroupId(groupId: number) {
    if (groupId === 0) {
      return {
        group_id: 0,
        process_class: "no_class" as GroupProcessClasses,
      };
    }

    const group = this.sequenceGroups.find((group) => group.group_id === groupId);
    return group as Sequence["groups"][number];
  }

  private _removeAnnotationsFromGroups(annotations: Annotation[]) {
    this.currentFrameGroups.forEach((group) => {
      group.annotations = group.annotations.filter((groupAnnotation) => {
        return !annotations.includes(groupAnnotation);
      });
    });
  }

  private _handleAnnotationCreation(annotation: Annotation) {
    this.canvasService.addAnnotationRect(annotation);

    const siblingGroup = this._getSiblingGroup(annotation);
    let group: AnnotationGroup;

    if (siblingGroup) {
      group = this.addAnnotationsToGroup(siblingGroup, annotation);
    } else {
      const noClassGroup = this.currentFrameGroups.find((group) => {
        return group.processClass === "no_class";
      }) as AnnotationGroup;

      group = this.addAnnotationsToGroup(noClassGroup, annotation);
    }

    const prefix = group.processClass === "no_class" ? "" : `#${group.groupId} `;

    this.canvasService.addAnnotationText(annotation, `${prefix}${group.processClass}`, group.color);
  }

  private _getDistanceBetweenAnnotations(annotation1: Annotation, annotation2: Annotation) {
    const annotation1CenterX = annotation1.left + annotation1.width / 2;
    const annotation1CenterY = annotation1.top + annotation1.height / 2;
    const annotation2CenterX = annotation2.left + annotation2.width / 2;
    const annotation2CenterY = annotation2.top + annotation2.height / 2;

    const clearHorizontalSpacing =
      Math.abs(annotation1CenterX - annotation2CenterX) -
      (annotation1.width + annotation2.width) / 2;
    const clearVerticalSpacing =
      Math.abs(annotation1CenterY - annotation2CenterY) -
      (annotation1.height + annotation2.height) / 2;

    return Math.max(clearHorizontalSpacing, clearVerticalSpacing, 0);
  }

  private _getGroupDensity(annotations: Annotation[]) {
    let totalDistance = 0;
    let comparisons = 0;

    for (let i = 0; i < annotations.length; i++) {
      for (let j = i + 1; j < annotations.length; j++) {
        totalDistance += this._getDistanceBetweenAnnotations(annotations[i], annotations[j]);
        comparisons++;
      }
    }

    const averageDistance = totalDistance / comparisons;
    return averageDistance;
  }

  private _getSiblingGroup(annotation: Annotation) {
    const PROXIMITY_THRESHOLD = 50;
    const DENSITY_THRESHOLD = 50;

    const annotationGroup = this._getGroupByAnnotation(annotation);
    const differentGroups = this.currentFrameGroups.filter((group) => group !== annotationGroup);

    let closestGroup = undefined;
    let closestDistance = Infinity;

    differentGroups.forEach((group) => {
      group.annotations.forEach((groupObj) => {
        const distance = this._getDistanceBetweenAnnotations(annotation, groupObj);
        const isClosest = distance < closestDistance && distance <= PROXIMITY_THRESHOLD;

        if (isClosest) {
          const isDense = this._getGroupDensity(group.annotations) <= DENSITY_THRESHOLD;

          if (isDense) {
            closestDistance = distance;
            closestGroup = group;
          }
        }
      });
    });

    return closestGroup;
  }

  private _getGroupByAnnotation(annotation: Annotation) {
    return this.currentFrameGroups.find((group) => group.annotations.includes(annotation));
  }

  private _keyboardGroupHandler(event: KeyboardEvent) {
    const activeAnnotations = this.canvasService?.getActiveAnnotations();

    const isAnnotationSelected = !!activeAnnotations.length;
    const isDigit = event.key.match(/\d/);

    if (!isAnnotationSelected || !isDigit || this.sequenceGroups.length < Number(event.key)) {
      return;
    }

    let group: AnnotationGroup;

    if (event.key === "0") {
      group = this.currentFrameGroups.find((group) => {
        return group.processClass === "no_class";
      }) as AnnotationGroup;
    } else {
      group = this.currentFrameGroups.find((group) => {
        return group.groupId === Number(event.key);
      }) as AnnotationGroup;

      if (!group) {
        const sequenceGroup = this._getSequenceGroupByGroupId(Number(event.key));
        group = this.createGroup(sequenceGroup.process_class, sequenceGroup.group_id);
      }
    }

    this.addAnnotationsToGroup(group, activeAnnotations);
  }

  private async _keyboardSaveHandler(event: KeyboardEvent) {
    if (event.key === "s" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      await this.saveAnnotations();
    }
  }

  private _keyboardSequenceImageSwitchHandler(event: KeyboardEvent) {
    const isArrowKey = ["ArrowLeft", "ArrowRight"].includes(event.key);
    const isADKey = ["KeyA", "KeyD"].includes(event.code);

    if (!isArrowKey && !isADKey) {
      return;
    }

    const isLeftArrow = event.key === "ArrowLeft" || event.code === "KeyA";
    const isRightArrow = event.key === "ArrowRight" || event.code === "KeyD";
    let newFrame = 0;

    if (isLeftArrow) {
      newFrame = (this.frameIndex - 1 + this.MAX_FRAMES_NUMBER) % this.MAX_FRAMES_NUMBER;
    } else if (isRightArrow) {
      newFrame = (this.frameIndex + 1) % this.MAX_FRAMES_NUMBER;
    }

    this.setFrameIndex(newFrame);
  }

  dispose() {
    this.cleanListeners.forEach((cleanListener) => cleanListener());
    this.cleanSaveInterval();
  }

  addKeyboardFeatures() {
    const eventHandler = async (event: KeyboardEvent) => {
      this._keyboardGroupHandler(event);
      this._keyboardSequenceImageSwitchHandler(event);
      await this._keyboardSaveHandler(event);
    };

    const removeEventListener = () => {
      window.removeEventListener("keydown", eventHandler);
    };

    window.addEventListener("keydown", eventHandler);

    this.cleanListeners.push(removeEventListener);
  }

  setFrameIndex(index: number) {
    if (index === this._frameIndex.value) {
      return;
    }

    this._frameIndex.value = index;
    this.canvasService.clearCanvas();
    this.canvasService.renderAnnotations(
      this.currentFrameGroups.flatMap((group) => group.annotations),
    );
  }

  getProcessClassColor(processClass: GroupProcessClasses = "no_class") {
    const processClassIndex = processClasses.findIndex((process) => {
      return process.decodedLabel === processClass;
    });

    const color = GROUP_COLORS[Math.abs(processClassIndex - 1) % GROUP_COLORS.length];

    return color;
  }

  isSequencesChanged(sequenceDump: SequenceExport, ignoreCompleted = false) {
    if (!this.sequence.completed && !ignoreCompleted) {
      return true;
    }

    const annotationMapper = (annotation: SequenceExport["frames"][0]["annotations"][0]) => ({
      bbox: annotation.bbox,
      group_id: annotation.group_id,
    });

    const isFramesEqual = this.sequence.frames.every((frame, index) => {
      const dumpFrame = sequenceDump.frames[index];
      const dumpFrameAnnotations = dumpFrame.annotations.map(annotationMapper);
      const frameAnnotations = frame.annotations.map(annotationMapper);

      return JSON.stringify(dumpFrameAnnotations) === JSON.stringify(frameAnnotations);
    });

    const mappedSavedSequenceGroups = this.sequence.groups.map((group) => ({
      ...group,
      process_class: SequenceRepository.decodedLabelToEncodedLabel(group.process_class),
    }));

    const isGroupsEqual =
      JSON.stringify(mappedSavedSequenceGroups) === JSON.stringify(sequenceDump.groups);

    return !isFramesEqual || !isGroupsEqual;
  }

  initFrameGroups(sequence: Sequence) {
    sequence.frames.forEach((frame, index) => {
      if (index >= this.allFrameGroups.value.length) {
        this.allFrameGroups.value.push([]);
      }

      this.setFrameIndex(index);

      if (!frame.annotations.some((group) => group.group_id === 0)) {
        this.createGroup("no_class", 0);
      }

      frame.annotations.forEach((frameAnnotation) => {
        const sequenceGroup = this._getSequenceGroupByGroupId(frameAnnotation.group_id);

        let frameGroup = this.currentFrameGroups.find(
          (group) => group.groupId === frameAnnotation.group_id,
        );

        if (!frameGroup) {
          frameGroup = this.createGroup(sequenceGroup.process_class, sequenceGroup.group_id);
        }

        const prefix = frameGroup?.processClass === "no_class" ? "" : `#${frameGroup?.groupId} `;

        const annotation = markRaw(
          this.canvasService.initAnnotation(
            frameAnnotation.bbox,
            `${prefix}${frameGroup.processClass}`,
            frameGroup.color,
          ),
        );

        frameGroup.annotations.push(annotation);
      });
    });
  }

  initAutoSave() {
    const interval = setInterval(async () => {
      await this.saveAnnotations();
    }, 1000 * 60 * 3);

    const removeInterval = () => {
      clearInterval(interval);
    };

    this.cleanSaveInterval = removeInterval;
  }

  createGroup(processClass: GroupProcessClasses, groupId?: number) {
    const color = this.getProcessClassColor(processClass);

    if (!groupId && groupId !== 0) {
      groupId = this._sequenceGroups.value.length + 1;
    }

    const group: AnnotationGroup = {
      id: this._generateId(),
      groupId: groupId,
      color: color,
      processClass: processClass,
      annotations: [],
    };

    this.currentFrameGroups.push(group);

    const isGroupExists = this._sequenceGroups.value.some((group) => group.group_id === groupId);
    if (groupId !== 0 && !isGroupExists) {
      this._sequenceGroups.value.push({
        group_id: groupId,
        process_class: processClass,
        visible: false,
      });
    }

    return group;
  }

  addAnnotationsToGroup(group: AnnotationGroup, annotation: Annotation | Annotation[]) {
    const annotations = Array.isArray(annotation) ? annotation : [annotation];

    this.currentFrameGroups.forEach((group) => {
      annotations.forEach((annotation) => {
        if (group.annotations.includes(annotation)) {
          group.annotations = group.annotations.filter((item) => item !== annotation);
        }
      });
    });

    group.annotations.push(...annotations.map(markRaw));
    group.annotations.forEach((annotation) => {
      const prefix = group.processClass === "no_class" ? "" : `#${group.groupId} `;
      this.canvasService.updateAnnotationText(annotation, `${prefix}${group.processClass}`);
      this.canvasService.changeAnnotationColor(annotation, group.color);
    });

    return group;
  }

  deleteGroup(group: AnnotationGroup) {
    const annotations = group.annotations;
    const noClassGroup = this.currentFrameGroups.find((group) => {
      return group.processClass === "no_class";
    }) as AnnotationGroup;

    this.currentFrameGroups.splice(this.currentFrameGroups.indexOf(group), 1);
    this.addAnnotationsToGroup(noClassGroup, annotations);
  }

  deleteSequenceGroup(group: Sequence["groups"][number]) {
    const isConfirmed = confirm("Are you sure you want to delete this group?");

    if (!isConfirmed) {
      return;
    }

    const currentFrameIndex = this.frameIndex;
    this.allFrameGroups.value.forEach((frameGroups, index) => {
      const groupToDelete = frameGroups.find((frameGroup) => {
        return frameGroup.groupId === group.group_id;
      });

      if (groupToDelete) {
        this.setFrameIndex(index);
        this.deleteGroup(groupToDelete as AnnotationGroup);
      }
    });

    this.setFrameIndex(currentFrameIndex);

    this._sequenceGroups.value = this._sequenceGroups.value.filter((sequenceGroup) => {
      return sequenceGroup.group_id !== group.group_id;
    });
  }

  async saveAnnotations(completeStatus?: boolean, ignoreSaveOnNoChange = false) {
    const exportDump = this.exportFrameGroups();
    const isSequenceChanged = this.isSequencesChanged(exportDump, ignoreSaveOnNoChange);
    if (!isSequenceChanged && ignoreSaveOnNoChange) {
      return;
    }

    if (completeStatus !== undefined) {
      exportDump.completed = completeStatus;
    } else if (!isSequenceChanged) {
      exportDump.completed = true;
    }

    const updatedSequence = await SequenceRepository.saveSequenceAnnotations(
      this._sequence.value._id,
      exportDump,
    );

    this._sequence.value = updatedSequence;
  }

  renderPctBboxes() {
    const bboxesToRender = this._sequenceGroups.value
      .filter((group) => group.visible && group.process_bbox)
      .map((group) => ({
        bbox: group.process_bbox as [number, number, number, number],
        text: `pct_box - ${group.process_class}`,
        color: this.getProcessClassColor(group.process_class),
      }));

    this.canvasService.renderTextBboxes(bboxesToRender);
  }

  toggleSequenceGroupVisibility(group: SequenceGroup) {
    if (!group.process_bbox) {
      return;
    }

    this._sequenceGroups.value = this._sequenceGroups.value.map((sequenceGroup) => {
      if (sequenceGroup.group_id === group.group_id) {
        sequenceGroup.visible = !sequenceGroup.visible;
      }

      return sequenceGroup;
    });

    this.renderPctBboxes();
  }

  getClosestFrameIndexWithGroup(groupId: number, direction: "next" | "prev") {
    const isCurrentGroup = (index: number) => {
      return this.allFrameGroups.value[index].some((group) => group.groupId === groupId);
    };

    if (direction === "next") {
      for (let i = this.frameIndex + 1; i < this.allFrameGroups.value.length; i++) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }

      for (let i = 0; i < this.frameIndex; i++) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }
    }

    if (direction === "prev") {
      for (let i = this.frameIndex - 1; i >= 0; i--) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }

      for (let i = this.allFrameGroups.value.length - 1; i > this.frameIndex; i--) {
        if (isCurrentGroup(i)) {
          return i;
        }
      }
    }

    return this.frameIndex;
  }

  exportFrameGroups() {
    const framePosition = this.canvasService.getBackgroundPosition();

    const sequenceGroups = this.sequenceGroups.map((group) => ({
      group_id: group.group_id,
      process_class: SequenceRepository.decodedLabelToEncodedLabel(group.process_class),
      process_bbox: group.process_bbox as [number, number, number, number],
    }));

    const frameAnnotations = this.allFrameGroups.value.map((frameGroups, index) => {
      const annotations = frameGroups.flatMap((group) => {
        return group.annotations.map((annotation) => {
          const x1 = (annotation.left - framePosition.left) / framePosition.width;
          const y1 = (annotation.top - framePosition.top) / framePosition.height;
          const x2 = x1 + annotation.width / framePosition.width;
          const y2 = y1 + annotation.height / framePosition.height;

          return {
            bbox: [
              Number(x1.toFixed(5)),
              Number(y1.toFixed(5)),
              Number(x2.toFixed(5)),
              Number(y2.toFixed(5)),
            ] as [number, number, number, number],
            group_id: group.groupId,
          };
        });
      });

      return {
        frame_id: this._sequence.value.frames[index].frame_id,
        annotations,
      };
    });

    return {
      groups: sequenceGroups,
      frames: frameAnnotations,
      completed: false,
    };
  }
}
