<template>
  <Modal :open="true" @close="$emit('closed')" customCls="w-full m-2 lg:w-1/4">
    <template #title>
      <h3 class="text-lg leading-6 font-medium text-gray-900">New Batch</h3></template
    >
    <template #content>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="text-left flex flex-col gap-4"
        v-slot="{ isSubmitting }"
      >
        <FloatField name="percentage" label="Percentage" />
        <button
          type="submit"
          class="focus:outline-none flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-0 disabled:cursor-default disabled:bg-gray-300"
          :disabled="isSubmitting"
        >
          <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
          Create
        </button>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Form, SubmissionHandler } from "vee-validate";
import { computed, Ref } from "vue";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import FloatField from "@/components/forms/FloatField.vue";
import { OpsReviewBatch, OpsReviewBatchToCreate } from "@/types/Validation";
import { useCreateOpsReviewBatch } from "@/views/process_validation/composables/opsReviewBatch";

const props = defineProps<{ startDate: Date; endDate: Date }>();
const emit = defineEmits<{ (eventName: "closed", opsReviewBatch?: OpsReviewBatch): void }>();

const minPercentage = 0.01;
const maxPercentage = 100;

const schema = yup.object({
  percentage: yup
    .number()
    .required("Percentage is required")
    .typeError("Percentage should be a valid number")
    .min(minPercentage, `Minimum percentage is ${minPercentage}`)
    .max(maxPercentage, `Maximum percentage is ${maxPercentage}`),
});

type OpsReviewBatchForm = Pick<OpsReviewBatchToCreate, "percentage">;

const initialValues: Ref<OpsReviewBatchForm> = computed(() => ({
  percentage: 10,
}));

const { createOpsReviewBatch } = useCreateOpsReviewBatch();

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as OpsReviewBatchForm;
  const opsReviewBatch = await createOpsReviewBatch({
    ...values,
    percentage: values.percentage / 100,
    start_date_local: props.startDate,
    end_date_local: props.endDate,
  });
  emit("closed", opsReviewBatch);
};
</script>
