<template>
  <MainLayout activeItem="UnitValuesView">
    <div class="flex items-center justify-center h-full" v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div
      v-if="!isLoading && unitValueAggregates && unitValueAggregatesByProject && projects"
      class="bg-gray-50 h-full pl-8 flex flex-col"
    >
      <div class="flex justify-between pr-8 my-4">
        <h1 class="text-3xl font-extrabold text-gray-900">Unit Value Benchmarks</h1>
        <Menu as="div" class="inline-block text-left leading-none" style="z-index: 50">
          <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
            <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-gray/5 focus:outline-none"
            >
              <div class="py-1 divide-y">
                <MenuItem v-slot="{ active }">
                  <button
                    type="button"
                    class="flex w-full items-center px-4 py-2 text-sm"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700']"
                    @click="showAllColumns = !showAllColumns"
                  >
                    <input
                      type="checkbox"
                      :checked="showAllColumns"
                      class="h-4 w-4 rounded border-gray-300 text-orange-400 focus:bg-gray-50 pointer-events-none mr-2"
                    />
                    <span> Show all columns</span>
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="flex flex-col overflow-hidden" :key="showAllColumns.toString()">
        <UnitValuesProjectsTable
          v-if="tableContext"
          v-bind="tableContext"
          :allProjects="projects"
          @scrolled="scroll.handleScroll"
          @scrollBarSizeChanged="scroll.handleScrollBarSizeChanged"
        />
        <UnitValuesAggregateTable
          v-if="tableContext"
          v-bind="tableContext"
          @scrolled="scroll.handleScroll"
        />
      </div>
    </div>
    <div v-else-if="!isLoading" class="flex items-center justify-center h-full">
      No unit values found
    </div>
  </MainLayout>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import MainLayout from "@/components/layout/MainLayout.vue";
import { useUnitValueAggregates, useUnitValueAggregatesByProject } from "@/composables/unitValues";
import { UnitValueTableSizes } from "@/types/UnitValue";
import UnitValuesAggregateTable from "@/views/unit_values/components/UnitValuesAggregateTable.vue";
import UnitValuesProjectsTable from "@/views/unit_values/components/UnitValuesProjectsTable.vue";
import { useScroll } from "@/views/unit_values/composables";

const tableSizes: UnitValueTableSizes = {
  projectColumnsWidth: 400,
  valueColumnWidth: 80,
  rowHeight: 33,
};

const store = useStore();
const { unitValueAggregates, areUnitValueAggregatesLoading } = useUnitValueAggregates();
const { unitValueAggregatesByProject, areUnitValueAggregatesByProjectLoading } =
  useUnitValueAggregatesByProject();
const projects = computed(() => store.state.projects);

const isLoading = computed(
  () => areUnitValueAggregatesLoading.value || areUnitValueAggregatesByProjectLoading.value,
);

const showAllColumns = ref(false);

const scroll = useScroll();

const tableContext = computed(() => {
  if (!unitValueAggregates.value || !unitValueAggregatesByProject.value) {
    return null;
  }
  return {
    tableSizes,
    scrollContext: scroll.context.value,
    unitValueAggregates: unitValueAggregates.value,
    unitValueAggregatesByProject: unitValueAggregatesByProject.value,
    showAllColumns: showAllColumns.value,
  };
});
</script>
