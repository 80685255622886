<template>
  <div class="relative pb-20">
    <div class="sticky top-0 z-10 bg-white border-b border-gray-300">
      <div class="h-16 flex items-center justify-between px-6 border-b border-gray-300">
        <span class="text-xl">Sequences</span>
        <button
          type="button"
          class="px-2 py-1 bg-green-500 text-white rounded"
          @click="createExport"
        >
          Export
        </button>
      </div>

      <div class="flex gap-4 px-24 py-4">
        <div>
          <p class="mb-1">Camera</p>
          <OaiCombobox
            v-model="streamFilter"
            :options="
              streams.map((stream) => ({
                name: `${stream.customer_name}/${stream.site_id} (${stream.camera_id})`,
                value: stream,
              }))
            "
            :placeholder="
              streamFilter.length ? `Selected ${streamFilter.length} cameras` : 'Select camera'
            "
            multiple
            :min-width="240"
          />
        </div>

        <div>
          <p class="mb-1">Process</p>
          <OaiCombobox
            :options="
              processClasses.map((process) => ({
                name: process.decodedLabel,
                value: process.decodedLabel,
              }))
            "
            :placeholder="
              processFilter.length ? `Selected ${processFilter.length} processes` : 'Select process'
            "
            v-model="processFilter"
            multiple
            :min-width="240"
          />
        </div>

        <div>
          <p class="mb-1">Date</p>
          <VueDatePicker
            v-model="datePickerRange"
            :columns="4"
            :enable-time-picker="false"
            placeholder="Select date range"
            format="dd.MM.yyyy"
            class="text-xs"
            locale="en"
            auto-apply
            range
          />
        </div>

        <div>
          <p class="mb-1">Status</p>
          <OaiListbox
            :options="[
              { name: 'All', value: undefined },
              { name: 'Completed', value: true },
              { name: 'Not completed', value: false },
            ]"
            v-model="statusFilter"
          />
        </div>

        <div class="ml-4">
          <p class="mb-1">Sort by</p>
          <div class="flex items-center gap-5">
            <OaiListbox
              :options="[
                { name: 'Date', value: 'date' },
                { name: 'Updated', value: 'updated' },
                { name: 'Customer', value: 'customer_name' },
              ]"
              v-model="sortBy"
            />

            <ArrowsUpDownIcon
              class="h-5 w-5 text-gray-400 cursor-pointer"
              aria-hidden="true"
              @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'"
            />
          </div>
        </div>
      </div>

      <div v-if="streamFilter.length" class="px-24 pb-4 flex gap-4 items-center">
        <span class="text-sm">Camera:</span>
        <div class="flex flex-wrap gap-3 items-center text-xs">
          <div
            v-for="item in streamFilter"
            :key="item._id"
            class="bg-gray-100 rounded-md px-2 py-1 font-medium text-gray-900 flex items-center"
          >
            <p class="mr-1">{{ item.customer_name }}/{{ item.site_id }} ({{ item.camera_id }})</p>

            <XMarkIcon
              class="h-4 w-4 text-red-500 cursor-pointer"
              @click="streamFilter = streamFilter.filter((stream) => stream._id !== item._id)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="px-20 py-5 flex gap-4 flex-wrap justify-between">
      <div
        class="p-4 relative rounded-md border border-transparent hover:border-gray-300 hover:shadow-md cursor-pointer"
        v-for="sequence in sequences"
        :key="sequence._id"
        @click="openSequence(sequence._id)"
      >
        <div class="w-56 h-40 mb-1 relative">
          <img
            :src="sequence.thumbnail_url"
            alt="thumbnail"
            class="w-full h-full object-cover"
            :key="sequence.thumbnail_url"
          />
          <div
            class="absolute top-0 left-0 h-full w-full bg-gray-900 bg-opacity-35 flex items-center"
            v-if="sequence.completed"
          >
            <p class="py-1 text-white font-bold bg-yellow w-full text-center">Completed</p>
          </div>

          <div
            v-if="!sequence.completed && sequence.updated_by !== 'oai-automatic'"
            class="absolute w-5 h-5 rounded-full bg-yellow -top-2.5 -right-2.5"
          />
        </div>
        <p class="text-sm mb-2">
          {{ sequence.customer_name }}/{{ sequence.site_id }}/{{ sequence.camera_id }}
        </p>

        <div class="flex text-xs gap-2">
          <span class="text-gray-600 flex-1">Date:</span>
          <span class="text-gray-600 flex-1">{{ sequence.date }}</span>
        </div>

        <div class="flex text-xs gap-2">
          <span class="text-gray-600 flex-1">Updated by:</span>
          <span class="text-gray-600 flex-1">{{ sequence.updated_by }}</span>
        </div>

        <div class="flex text-xs gap-2">
          <span class="text-gray-600 flex-1">Last updated:</span>
          <span class="text-gray-600 flex-1">{{
            format(sequence.updated, "dd.MM.yy HH:mm:ss")
          }}</span>
        </div>
      </div>
    </div>

    <button
      class="ml-24 flex text-xs items-center hover:text-white hover:bg-orange bg-gray-100 px-3 py-1 rounded-md leading-none border"
      @click="step += 1"
    >
      <ArrowDownIcon class="h-3 w-3" /> Load more
    </button>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon, ArrowsUpDownIcon, ArrowDownIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format } from "date-fns";
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import OaiCombobox from "shared/components/other/OaiCombobox.vue";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import { useCustomToast } from "shared/composables/toast";
import getProcessClasses from "shared/constants/ProcessClasses";
import logger from "shared/services/logger";
import { DecodedLabel, EncodedLabel } from "shared/types/ProcessClass";
import { Stream } from "shared/types/Stream";
import { useAllStreams } from "@/composables/stream";
import SequenceRepository from "@/repositories/SequenceRepository";
import { usePersonGadSequences } from "@/views/person_gad/composables";

const processClasses = getProcessClasses();
const { streams } = useAllStreams();
const toast = useCustomToast();
const router = useRouter();
const route = useRoute();
const query = new URLSearchParams(route.query as Record<string, string>);

const streamFilter = ref<Stream[]>([]);
const processFilter = ref<DecodedLabel[]>([]);
const datePickerRange = ref<[Date, Date]>();
const statusFilter = ref<string>();
const sortBy = ref("date");
const sortOrder = ref("desc");
const step = ref(1);

router.replace({ query: undefined });

onMounted(() => {
  if (query.has("stream_id")) {
    const streamIds = query.getAll("stream_id");
    streamFilter.value = streams.value.filter((stream) => streamIds.includes(stream._id));
  }

  if (query.has("process_class")) {
    const processClasses = query.getAll("process_class").map(Number) as EncodedLabel[];
    processFilter.value = processClasses.map(
      (process) => SequenceRepository.encodedLabelToDecodedLabel(process) as DecodedLabel,
    );
  }

  if (query.has("completed")) {
    statusFilter.value = query.get("completed") as string;
  }

  if (query.has("start_date") && query.has("end_date")) {
    datePickerRange.value = [
      new Date(query.get("start_date") as string),
      new Date(query.get("end_date") as string),
    ];
  }

  if (query.has("sort_by")) {
    sortBy.value = query.get("sort_by") as string;
  }

  if (query.has("sort_order")) {
    sortOrder.value = query.get("sort_order") as string;
  }

  if (query.has("step")) {
    step.value = Number(query.get("step"));
  }
});

const sequencesQuery = computed(() => {
  const query = new URLSearchParams();

  const streamIds = streamFilter.value.map((stream) => stream._id);
  streamIds.forEach((streamId) => query.append("stream_id", streamId));

  processFilter.value.forEach((process) =>
    query.append("process_class", String(SequenceRepository.decodedLabelToEncodedLabel(process))),
  );

  if (statusFilter.value !== undefined) {
    query.append("completed", statusFilter.value.toString());
  }

  if (datePickerRange.value?.[0] && datePickerRange.value?.[1]) {
    query.append("start_date", format(datePickerRange.value[0], "yyyy-MM-dd"));
    query.append("end_date", format(datePickerRange.value[1], "yyyy-MM-dd"));
  }

  query.append("step", String(step.value));
  query.append("sort_by", sortBy.value);
  query.append("sort_order", sortOrder.value);

  return query;
});

const { sequences } = usePersonGadSequences(sequencesQuery);

const createExport = async () => {
  try {
    await SequenceRepository.createSequencesDump();

    toast.success("Export created");
  } catch (_) {
    logger.error("Export creation failed");
    toast.error("Export creation failed");
  }
};

const openSequence = (sequenceId: string) => {
  router.push(`/person-gad/${sequenceId}?${sequencesQuery.value.toString()}`);
};
</script>
