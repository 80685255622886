import { AxiosRequestConfig, AxiosResponse } from "axios";
import { format } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { apiClient } from "shared/repositories/clients";
import { SectionMaskRemappingChanges } from "shared/types/Process";
import { ProcessFilters } from "@/types/Process";
import {
  ReviewProcess,
  MappingProcess,
  ReviewStatus,
  ReviewStatusOverviewResponse,
  TimelapseStatusEntry,
  ValidationReport,
  OpsReviewDuration,
  SectionMaskRemappingFilter,
  OpsReview,
  OpsReviewedProcessWithErrors,
} from "@/types/Validation";

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const loadPrdPipelineData = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<ReviewProcess[]> =>
  apiClient
    .get<void, AxiosResponse<ReviewProcess[]>>(
      `/prd-validation/pipeline/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => response.data);

const loadOpsReviewCameraDay = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<ReviewProcess[]> =>
  apiClient
    .get<void, AxiosResponse<ReviewProcess[]>>(
      `/prd-validation/ops-review/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => response.data);

const loadOpsReviewSite = (customerName: string, siteId: string): Promise<MappingProcess[]> =>
  apiClient
    .get<void, AxiosResponse<MappingProcess[]>>(
      `/prd-validation/ops-review/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const saveOpsReviewData = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
  mode: string,
  updatedProcesses: ReviewProcess[],
  opsReviewDuration: OpsReviewDuration,
): Promise<ReviewProcess[]> =>
  apiClient
    .post<void, AxiosResponse<ReviewProcess[]>>(
      `/prd-validation/ops-review/${mode}/${customerName}/${siteId}/${cameraId}/${date}`,
      {
        items: updatedProcesses.map((process) => ({
          ...process,
          start_time: undefined,
          end_time: undefined,
          breaks: undefined,
        })),
        ops_review_duration: opsReviewDuration,
      },
    )
    .then((response) => response.data);

const loadReviewStatusSite = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<ReviewStatus[]> =>
  apiClient
    .get<void, AxiosResponse<ReviewStatus[]>>(
      `/prd-validation/review-status/site/${customerName}/${siteId}/${date}`,
    )
    .then((response) => response.data);

const loadReviewStatusOverview = (
  startDate: Date,
  endDate: Date,
  projectStatus: string | null = null,
  filteredUser: string | null = null,
): Promise<ReviewStatusOverviewResponse> => {
  const searchParams = new URLSearchParams();
  if (projectStatus) {
    searchParams.set("project_status", projectStatus);
  }

  if (filteredUser) {
    searchParams.set("user", filteredUser);
  }

  return apiClient
    .get<void, AxiosResponse<ReviewStatusOverviewResponse>>(
      `/prd-validation/review-status/overview/${format(startDate, "yyyy-MM-dd")}/${format(
        endDate,
        "yyyy-MM-dd",
      )}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const loadTimelapseStatusOverview = (
  startDate: Date,
  endDate: Date,
  projectStatus: string | null = null,
): Promise<Record<string, TimelapseStatusEntry>> => {
  const searchParams = new URLSearchParams();
  if (projectStatus) {
    searchParams.set("project_status", projectStatus);
  }
  return apiClient
    .get<void, AxiosResponse<Record<string, TimelapseStatusEntry>>>(
      `/prd-validation/review-status/tml/${format(startDate, "yyyy-MM-dd")}/${format(
        endDate,
        "yyyy-MM-dd",
      )}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const remapOpsReviewProcessesToPlan = (
  customerName: string,
  siteId: string,
): Promise<MappingProcess[]> =>
  apiClient
    .post<void, AxiosResponse<MappingProcess[]>>(
      `/prd-validation/ops-review/planner-remapping/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const remapOpsReviewProcessesToSectionMasks = (
  customerName: string,
  siteId: string,
  filters: SectionMaskRemappingFilter,
  apply_section_changes = false,
  apply_conflict_changes = false,
): Promise<SectionMaskRemappingChanges> =>
  apiClient
    .post<void, AxiosResponse<SectionMaskRemappingChanges>>(
      `/prd-validation/ops-review/section-remapping/${customerName}/${siteId}`,
      {
        filters: {
          camera_ids: filters.camera_ids,
          start_date: filters.start_date,
          end_date: filters.end_date,
        },
        apply_section_changes: apply_section_changes,
        apply_conflict_changes: apply_conflict_changes,
      },
    )
    .then((response) => response.data);

const loadOpsReviewReport = (params?: {
  customerName?: string;
  siteId?: string;
  cameraId?: string;
  startDate?: string;
  endDate?: string;
  activeOnly?: boolean;
}) => {
  const formattedParams = {
    ...(params?.customerName ? { customer_name: params.customerName } : {}),
    ...(params?.siteId ? { site_id: params.siteId } : {}),
    ...(params?.cameraId ? { camera_id: params.cameraId } : {}),
    ...(params?.startDate ? { start_date: params.startDate } : {}),
    ...(params?.endDate ? { end_date: params.endDate } : {}),
    ...(params?.activeOnly ? { active_only: params.activeOnly } : {}),
  };

  const config: AxiosRequestConfig = {
    params: formattedParams,
  };
  return apiClient
    .get<void, AxiosResponse<ValidationReport>>(`/prd-validation/ops-review/report`, config)
    .then((response) => response.data);
};

const loadConflictingProcesses = (
  sortBy: string | undefined,
  filter: "ignored" | undefined,
): Promise<{ processes: MappingProcess[] }> => {
  const config: AxiosRequestConfig = {
    params: {
      sort_by: sortBy,
      filter,
    },
  };
  return apiClient
    .get<void, AxiosResponse<{ processes: MappingProcess[] }>>(
      `/prd-validation/conflicting-processes`,
      config,
    )
    .then((response) => ({
      ...response.data,
      processes: response.data.processes,
    }));
};

const loadConflictingProcessesCount = (): Promise<{ count: number }> =>
  apiClient
    .get<void, AxiosResponse<{ count: number }>>(`/prd-validation/conflicting-processes/count`)
    .then((response) => response.data);

type ProcessToUpdate = Partial<
  Pick<MappingProcess, "encoded_label" | "location"> & {
    work_intervals: (Pick<MappingProcess["work_intervals"][0], "start_time" | "end_time"> & {
      workforce: Pick<MappingProcess["work_intervals"][0]["workforce"], "validated_count">;
    })[];
  } & {
    section_mask_mapping: {
      id: MappingProcess["section_mask_mapping"]["id"];
      level_id: MappingProcess["section_mask_mapping"]["level_id"];
    };
  }
>;

const updateProcess = (
  customerName: string,
  siteId: string,
  processDbId: string,
  process: ProcessToUpdate,
  opsReviewDuration?: OpsReviewDuration,
): Promise<MappingProcess> =>
  apiClient
    .patch<void, AxiosResponse<MappingProcess>>(
      `/prd-validation/processes/${customerName}/${siteId}/${processDbId}`,
      {
        encoded_label: process.encoded_label,
        work_intervals: process.work_intervals?.map((work_interval) => ({
          start_time: work_interval.start_time,
          end_time: work_interval.end_time,
          workforce: work_interval.workforce
            ? {
                validated_count: work_interval.workforce.validated_count,
              }
            : undefined,
        })),
        location: process.location,
        section_mask_mapping: process.section_mask_mapping && {
          id: process.section_mask_mapping.id,
          level_id: process.section_mask_mapping.level_id,
        },
        ...(opsReviewDuration && { ops_review_duration: opsReviewDuration }),
      },
    )
    .then((response) => response.data);

const deprecateProcess = (processDbId: string): Promise<void> =>
  apiClient
    .post<void, AxiosResponse<void>>(`/prd-validation/processes/${processDbId}/deprecate`)
    .then((response) => response.data);

const ignoreProcess = (processDbId: string, ignored: boolean): Promise<MappingProcess> =>
  apiClient
    .post<void, AxiosResponse<MappingProcess>, { ignored: boolean }>(
      `/prd-validation/processes/${processDbId}/ignore`,
      {
        ignored,
      },
    )
    .then((response) => response.data);

const loadMappableEncodedLabels = (
  processDbId: string,
  sectionMaskId?: string | null,
): Promise<number[]> => {
  const searchParams = new URLSearchParams();
  if (sectionMaskId) {
    searchParams.set("section_mask_id", sectionMaskId);
  }
  return apiClient
    .get<void, AxiosResponse<number[]>>(
      `/prd-validation/processes/${processDbId}/mappable-encoded-labels?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const loadProcesses = (
  filters: ProcessFilters,
  sortBy: string | undefined,
  pageSize: number,
  skip: number | undefined,
): Promise<MappingProcess[]> =>
  apiClient
    .post<void, AxiosResponse<MappingProcess[]>>(`/prd-validation/processes`, {
      filters: {
        customer_name: filters.customerName ?? null,
        site_id: filters.siteId ?? null,
        camera_id: filters.cameraId ?? null,
        process_class: filters.processClass ?? null,
        start_date: filters.startDate ? formatUtcDate(filters.startDate) : null,
        end_date: filters.endDate ? formatUtcDate(filters.endDate) : null,
        building: filters.building ?? null,
        level: filters.level ?? null,
        section: filters.section ?? null,
        mappingState: filters.mappingState ?? null,
      },
      sort_by: sortBy ?? null,
      page_size: pageSize,
      skip: skip ?? null,
    })
    .then((response) => response.data);

const loadOpsReviewsForBatch = (batchId: string) =>
  apiClient
    .get<OpsReview[]>(`/prd-validation/ops-reviews/for-batch/${batchId}`)
    .then((response) => response.data);

const loadOpsReviewedProcessesWithErrorsForBatches = (batchIds: string[]) =>
  apiClient
    .post<OpsReviewedProcessWithErrors[]>("/prd-validation/ops-reviews/process-error-scores", {
      batch_ids: batchIds,
    })
    .then((response) => response.data);

export default {
  loadPrdPipelineData,
  loadOpsReviewCameraDay,
  loadOpsReviewSite,
  loadReviewStatusSite,
  loadReviewStatusOverview,
  loadTimelapseStatusOverview,
  saveOpsReviewData,
  remapOpsReviewProcessesToPlan,
  remapOpsReviewProcessesToSectionMasks,
  loadOpsReviewReport,
  loadConflictingProcesses,
  loadConflictingProcessesCount,
  loadMappableEncodedLabels,
  ignoreProcess,
  loadProcesses,
  updateProcess,
  deprecateProcess,
  loadOpsReviewsForBatch,
  loadOpsReviewedProcessesWithErrorsForBatches,
};
