<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-5 xl:w-1/3">
    <template #title>AWS Stream Name</template>
    <template #content>
      <div class="h-[350px]">
        <div v-if="isLoading" class="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
        <div class="text-left flex flex-col gap-4 h-full" v-else>
          <div class="flex flex-col gap-4 text-gray-600 flex-1 overflow-auto">
            <Combobox as="div" :modelValue="query" @update:modelValue="query = $event" nullable>
              <div class="relative">
                <ComboboxInput
                  class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                  @change="query = sanitizeAwsStreamId($event.target.value) || ''"
                  autocomplete="one-time-code"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>
                <ComboboxOptions
                  v-if="filteredAwsStreamIds.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ComboboxOption
                    v-for="awsStreamId in filteredAwsStreamIds"
                    :key="awsStreamId"
                    :value="awsStreamId"
                    as="template"
                    v-slot="{ active, selected }"
                  >
                    <li
                      :class="[
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-orange-600 text-white' : 'text-gray-900',
                      ]"
                    >
                      <span :class="['block truncate', selected && 'font-semibold']">
                        {{ awsStreamId }}
                        <span
                          class="bg-orange-300 text-white rounded px-1 py-0.5 text-xs ml-1"
                          v-if="assignedAwsStreamIds.has(awsStreamId)"
                          >assigned</span
                        >
                      </span>
                      <span
                        v-if="selected"
                        :class="[
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-orange-600',
                        ]"
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
            <div
              v-if="streamsByAwsStreamId[query]"
              class="flex flex-col gap-2 text-sm overflow-auto"
            >
              <div class="bg-orange-300 text-white rounded px-1 py-0.5 w-min whitespace-nowrap">
                Assigned
              </div>
              <div class="flex flex-col gap-2 ml-1">
                <RouterLink
                  :to="{
                    name: 'ProjectConsole',
                    query: {
                      customer_name: stream.customer_name,
                      site_id: stream.site_id,
                      tab: 'streams',
                    },
                  }"
                  v-for="stream in streamsByAwsStreamId[query]"
                  :key="stream._id"
                  @click="emit('close')"
                >
                  &#x2022; {{ stream.customer_name }}/{{ stream.site_id }}/{{
                    stream.camera_id
                  }}
                  ({{ stream.name }})
                </RouterLink>
              </div>
            </div>
            <div
              v-if="query && !streamsByAwsStreamId[query]"
              class="bg-green-200 text-white rounded px-1 py-0.5 w-min whitespace-nowrap text-sm"
            >
              not assigned
            </div>
          </div>
          <button
            type="submit"
            :disabled="!query || isUpdateLoading"
            class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 disabled:bg-gray-200"
            @click="handleSaveClick"
          >
            Save
          </button>
        </div>
      </div></template
    >
  </Modal>
</template>

<script lang="ts" setup>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { computed, onMounted, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import { Stream } from "shared/types/Stream";
import StreamRepository from "@/repositories/StreamRepository";
import { vivotek5mpPreset } from "@/views/project_console/projectConsole";

const props = defineProps<{ stream: Stream }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "update", stream: Stream): void;
}>();

const isLoading = ref(true);
const isUpdateLoading = ref(false);
const streams = ref<Stream[]>([]);

const query = ref(props.stream.aws_stream_id || "");

const sanitizeAwsStreamId = (awsStreamId: string) => awsStreamId.toLowerCase().trim() || null;

const streamsWithSanitizedAwsStreamId = computed(() =>
  streams.value.map((stream) => ({
    ...stream,
    aws_stream_id: stream.aws_stream_id ? sanitizeAwsStreamId(stream.aws_stream_id) : null,
  })),
);

const uniqueAwsStreamIds = computed(() => {
  const awsStreamIds = [
    ...new Set(
      streamsWithSanitizedAwsStreamId.value
        .filter((stream) => stream.aws_stream_id)
        .map((stream) => stream.aws_stream_id as string),
    ),
  ];
  awsStreamIds.sort();
  return awsStreamIds;
});

const assignedAwsStreamIds = computed(
  () =>
    new Set(
      streamsWithSanitizedAwsStreamId.value
        .filter((stream) => stream.aws_stream_id && !stream.is_deprecated)
        .map((stream) => stream.aws_stream_id as string),
    ),
);

const streamsByAwsStreamId = computed(() =>
  streamsWithSanitizedAwsStreamId.value.reduce((acc, stream) => {
    if (stream.aws_stream_id && !stream.is_deprecated) {
      if (!acc[stream.aws_stream_id]) {
        acc[stream.aws_stream_id] = [];
      }
      acc[stream.aws_stream_id].push(stream);
    }
    return acc;
  }, {} as Record<string, Stream[]>),
);

const filteredAwsStreamIds = computed(() =>
  query.value
    ? uniqueAwsStreamIds.value.filter((awsStreamId) => awsStreamId.includes(query.value))
    : uniqueAwsStreamIds.value,
);

const handleSaveClick = () => {
  if (!query.value || isUpdateLoading.value) {
    return;
  }

  isUpdateLoading.value = true;

  const preset =
    props.stream.resolution_width === null &&
    props.stream.resolution_height === null &&
    props.stream.fps === null
      ? vivotek5mpPreset
      : null;

  const streamToUpdate = {
    aws_stream_id: query.value,
    ...preset,
  };

  StreamRepository.updateStream(
    props.stream.customer_name,
    props.stream.site_id,
    props.stream.camera_id,
    streamToUpdate,
  )
    .then((stream) => emit("update", stream))
    .catch((error) => {
      logger.error(error);
      useCustomToast().error("Unable to update stream");
    })
    .finally(() => {
      isUpdateLoading.value = false;
    });
};

onMounted(() => {
  StreamRepository.loadAllStreamsWithDeprecated()
    .then((result) => {
      streams.value = result;
    })
    .catch((error) => {
      logger.error(error);
      useCustomToast().error("Unable to load streams");
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>
