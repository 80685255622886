import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import OpsReviewBatchRepository from "@/repositories/OpsReviewBatchRepository";
import { OpsReviewBatch, OpsReviewBatchToCreate } from "@/types/Validation";

export const useOpsReviewBatches = () => {
  const { data, isLoading } = useQuery<OpsReviewBatch[]>({
    queryKey: ["ops-review-batches"],
    queryFn: () => OpsReviewBatchRepository.loadOpsReviewBatches(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load ops review batches");
      return false;
    },
  });

  return { opsReviewBatches: data, isLoading };
};

export const useCreateOpsReviewBatch = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createOpsReviewBatch, isLoading } = useMutation<
    OpsReviewBatch,
    Error,
    OpsReviewBatchToCreate
  >({
    mutationFn: (opsReviewBatchToCreate) =>
      OpsReviewBatchRepository.createOpsReviewBatch(opsReviewBatchToCreate),
    onSuccess: (opsReviewBatch) => {
      queryClient.setQueryData(
        ["ops-review-batches"],
        (data?: OpsReviewBatch[]) => data && [...data, opsReviewBatch],
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to create ops review batch");
      return false;
    },
  });

  return { createOpsReviewBatch, isLoading };
};

export const useDeleteOpsReviewBatch = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteOpsReviewBatch, isLoading } = useMutation<void, Error, string>({
    mutationFn: (batchId) => OpsReviewBatchRepository.deleteOpsReviewBatch(batchId),
    onSuccess: (result, batchId) => {
      queryClient.setQueryData(["ops-review-batches"], (data?: OpsReviewBatch[]) =>
        data?.filter((opsReviewBatch) => opsReviewBatch._id !== batchId),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to delete ops review batch");
      return false;
    },
  });

  return { deleteOpsReviewBatch, isLoading };
};
