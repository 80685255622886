<template>
  <Modal
    :open="open"
    customCls="w-11/12"
    @close="$emit('closeModal'), updateSectionMasks(), resetCurrentOption()"
  >
    <template #title>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div class="inline-flex">Section Mask</div>
          <div class="inline-flex pl-4 h-10 w-10" v-if="!loaded">
            <LoadingSpinner />
          </div>
        </div>
        <div class="flex items-center space-x-1" style="margin-right: 30px">
          <div class="flex items-center">
            <div class="inline-flex mr-2 mb-3 h-6 w-6" v-if="isLoadingImages">
              <LoadingSpinner />
            </div>
            <input
              type="date"
              max="9999-12-12T00:00:00.00"
              id="searchDate"
              class="form-control max-w-lg block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md mr-2"
              v-model="imageDate"
            />
            <button
              type="button"
              class="bg-yellow-400 hover:bg-yellow-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm"
              @click="changeImageDate"
            >
              <span>Update Masks & Images</span>
            </button>
          </div>
          <div class="px-3 text-gray-400">|</div>
          <div v-for="i in 3" :key="i">
            <button
              type="button"
              :class="[
                i === amountColumns
                  ? 'bg-yellow-400 hover:bg-yellow-600'
                  : 'bg-gray-400 hover:bg-gray-600',
                'inline-flex items-center rounded-md px-3 py-1 text-sm font-medium text-white shadow-sm',
              ]"
              @click="amountColumns = i"
            >
              <span>{{ i }}</span>
            </button>
          </div>
        </div>
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-3">
          <div class="overflow-auto" :style="`max-height: calc(95vh - 150px);`">
            <div :class="`grid grid-cols-${amountColumns} gap-4`">
              <div v-for="stream in streams" :key="stream.camera_id">
                <span class="font-semibold">{{ `${stream.name} - ${stream.camera_id}` }}</span>
                <div
                  @click="setActiveCanvas(stream.camera_id)"
                  :class="[
                    currentCanvasId === stream.camera_id ? 'border-green-300' : 'border-white',
                    'col-span-1 border-2',
                  ]"
                >
                  <canvas
                    :ref="`canvas-${stream.camera_id}`"
                    class="bg-gray-100"
                    :style="{
                      cursor: editMode ? 'crosshair' : 'default',
                      width: '100%',
                      display: 'block',
                    }"
                    @click="
                      stream.camera_id in canvasMap && editMode && loaded
                        ? canvasMap[stream.camera_id]?.drawLine($event)
                        : canvasMap[stream.camera_id]?.editSubMask($event)
                    "
                    @contextmenu.prevent="
                      stream.camera_id in canvasMap && editMode && loaded
                        ? handleClosePolygon(stream.camera_id)
                        : canvasMap[stream.camera_id].unfocusSubMask()
                    "
                    @mousedown="
                      !editMode &&
                        loaded &&
                        canvasMap[stream.camera_id]?.onMouseDownDragging($event)
                    "
                    @mousemove="
                      !editMode &&
                        loaded &&
                        canvasMap[stream.camera_id]?.onMouseMoveDragging($event)
                    "
                    @mouseup="!editMode && loaded && handleOnMouseUp(stream.camera_id)"
                    @keydown.u="editMode && loaded && undoEventListener()"
                    tabindex="0"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="flex justify-between">
            <div class="flex items-center">
              <div v-if="hasLevels" class="mr-2">
                <select
                  id="level-selector"
                  name="level-name"
                  class="block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md"
                  style="width: 100%"
                  v-model="selectedLevelId"
                >
                  <option :value="null" disabled selected>Select a level</option>
                  <option v-for="option in levelOptions" :key="option" :value="option">
                    {{ formatTagText(tagMap[option]) }}
                  </option>
                  <option v-if="hasEmptyLevel" key="empty" :value="null">Empty Level</option>
                </select>
              </div>
              <div v-if="hasLevels">
                <button @click="selectedLevelId = ''" class="pt-2">
                  <XMarkIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div
            class="font-normal text-xs py-1"
            v-if="processTimeRangeForFilter.start && processTimeRangeForFilter.end"
          >
            Processes between:
            <span
              class="hover:underline cursor-pointer"
              @click="updateImagesAndFocusMasks(processTimeRangeForFilter.start)"
              >{{ formatDateDisplay(processTimeRangeForFilter.start) }}</span
            >
            -
            <span
              class="hover:underline cursor-pointer"
              @click="updateImagesAndFocusMasks(processTimeRangeForFilter.end)"
              >{{ formatDateDisplay(processTimeRangeForFilter.end) }}</span
            >
          </div>
          <div>
            <div
              class="-mx-4 -my-2 overflow-auto sm:-mx-6 lg:-mx-8"
              :style="`max-height: calc(90vh - 150px);`"
            >
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                v-if="sectionOptions.length > 0"
              >
                <table class="min-w-full divide-y divide-gray-300 mt-4">
                  <tbody class="divide-y divide-gray-200">
                    <template v-for="option in sectionOptions" :key="option">
                      <tr
                        :class="[
                          isCurrentOption(option)
                            ? 'bg-yellow-300'
                            : hasMask(option)
                            ? 'bg-green-50'
                            : '',
                          'cursor-pointer',
                        ]"
                        @click="handleEditMask(option)"
                      >
                        <div class="flex justify-between">
                          <div class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                            <p>
                              <span>{{
                                getDisplayedBuildingLevelName(
                                  option.building_id,
                                  option.level_split_id || option.level_id,
                                )
                              }}</span>
                            </p>
                            <p class="font-semibold" v-if="option.section_id">
                              {{ formatTagText(tagMap[option.section_id]) }}
                            </p>
                            <p class="font-semibold pt-2" v-else>
                              <span class="bg-blue text-blue-800 p-1 rounded-md">Overlap Mask</span>
                            </p>
                          </div>
                          <div
                            class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 flex justify-end"
                          >
                            <div v-if="isCurrentOption(option)" @click.stop>
                              <button
                                v-if="levelOptions.length > 0"
                                @click="copyMaskMode = true"
                                class="rounded-full bg-gray-500 mr-2"
                              >
                                <Square2StackIcon
                                  class="h-5 w-5 text-white m-1"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </tr>
                      <div v-if="isCurrentOption(option)">
                        <div
                          v-for="(groupedMasks, cameraId) in getGroupedSectionMasksForOption(
                            option,
                          )"
                          :key="cameraId"
                          class="text-xs p-1 whitespace-nowrap"
                        >
                          <div class="font-semibold flex justify-between my-1">
                            <div class="flex items-center">
                              <div>{{ cameraId }}</div>
                              <button
                                @click="
                                  validityMaskOption = option;
                                  validityMaskCameraId = cameraId as string;
                                "
                                v-if="
                                  !(
                                    validityMaskOption?.building_id === option.building_id &&
                                    validityMaskOption.level_id === option.level_id &&
                                    validityMaskOption.level_split_id === option.level_split_id &&
                                    validityMaskOption.section_id === option.section_id &&
                                    validityMaskCameraId === cameraId
                                  )
                                "
                              >
                                <PlusIcon
                                  class="h-4 w-4 ml-2 hover:text-yellow-700"
                                  aria-hidden="true"
                                />
                              </button>
                              <div v-else class="flex items-center">
                                <input
                                  id="add-validity-mask-timestamp"
                                  type="datetime-local"
                                  class="ml-2 form-control border-gray-300 rounded-md w-36 h-6"
                                  style="font-size: 10px"
                                  v-model="validityMaskTimestamp"
                                />
                                <button
                                  @click="
                                    validityMaskOption = null;
                                    validityMaskCameraId = null;
                                  "
                                >
                                  <XMarkIcon
                                    class="h-4 w-4 ml-1 hover:text-yellow-700 mt-1"
                                    aria-hidden="true"
                                  />
                                </button>
                                <button
                                  @click="
                                    addValidityMask();
                                    validityMaskOption = null;
                                    validityMaskCameraId = null;
                                  "
                                >
                                  <CheckIcon
                                    class="h-4 w-4 ml-1 hover:text-green-700 mt-1"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div
                              class="font-normal text-xs py-1"
                              v-if="option && getProcessTimeRangeForSection(option, cameraId as string).start && getProcessTimeRangeForSection(option, cameraId as string).end"
                            >
                              Processes between:
                              <span
                                class="hover:underline cursor-pointer"
                                @click="
                                  updateImagesAndFocusMasks(
                                    getProcessTimeRangeForSection(option, cameraId as string).start,
                                  )
                                "
                                >{{
                                  formatDateDisplay(
                                    getProcessTimeRangeForSection(option, cameraId as string).start,
                                  )
                                }}</span
                              >
                              -
                              <span
                                class="hover:underline cursor-pointer"
                                @click="
                                  updateImagesAndFocusMasks(
                                    getProcessTimeRangeForSection(option, cameraId as string).end,
                                  )
                                "
                                >{{
                                  formatDateDisplay(
                                    getProcessTimeRangeForSection(option, cameraId as string).end,
                                  )
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            v-for="(mask, index) in groupedMasks"
                            :key="index"
                            class="flex items-center py-1"
                          >
                            <div
                              @click="
                                validityRangeToEdit?.mask_id !== mask._id && focusMask(mask, true)
                              "
                              :class="[
                                mask.show_on_canvas ? 'bg-yellow-200' : '',
                                'rounded-md px-1.5 py-0.5 cursor-pointer',
                              ]"
                            >
                              <div class="pr-1">
                                {{ `#${index + 1}: ` }}
                                <span
                                  v-if="
                                    validityRangeToEdit && validityRangeToEdit.mask_id === mask._id
                                  "
                                >
                                  <input
                                    id="validity-range-start"
                                    type="datetime-local"
                                    class="ml-2 form-control border-gray-300 rounded-md w-36 h-6"
                                    style="font-size: 10px"
                                    v-model="validityRangeToEdit.start"
                                /></span>
                                <span v-else>{{
                                  mask.validity_start_local
                                    ? formatTimestampDisplay(mask.validity_start_local)
                                    : "start"
                                }}</span>
                                -
                                <span
                                  v-if="
                                    validityRangeToEdit && validityRangeToEdit.mask_id === mask._id
                                  "
                                >
                                  <input
                                    id="validity-range-end"
                                    type="datetime-local"
                                    class="ml-2 form-control border-gray-300 rounded-md w-36 h-6"
                                    style="font-size: 10px"
                                    v-model="validityRangeToEdit.end"
                                /></span>
                                <span v-else>{{
                                  mask.validity_end_local
                                    ? formatTimestampDisplay(mask.validity_end_local)
                                    : "today"
                                }}</span>
                              </div>
                            </div>
                            <template v-if="mask.show_on_canvas">
                              <button
                                v-if="!validityRangeToEdit"
                                @click="setValidityRangeToEdit(mask)"
                              >
                                <PencilIcon
                                  class="h-4 w-4 ml-2 hover:text-yellow-800"
                                  aria-hidden="true"
                                />
                              </button>
                              <template v-else>
                                <button @click="updateValidityMaskRange(mask)">
                                  <CheckIcon
                                    class="h-4 w-4 ml-2 hover:text-green-700"
                                    aria-hidden="true"
                                  />
                                </button>
                                <button @click="resetValidityRangeToEdit()">
                                  <XMarkIcon
                                    class="h-4 w-4 ml-2 hover:text-red-700"
                                    aria-hidden="true"
                                  />
                                </button>
                              </template>
                            </template>
                            <template v-if="validityRangeToEdit?.mask_id !== mask._id">
                              <button @click="clearMask(mask)">
                                <ScissorsIcon
                                  class="h-4 w-4 ml-2 hover:text-red-700"
                                  aria-hidden="true"
                                />
                              </button>
                              <button @click="deleteMask(mask)">
                                <TrashIcon
                                  class="h-4 w-4 ml-2 hover:text-red-700"
                                  aria-hidden="true"
                                />
                              </button>
                              <div class="relative" v-if="mask.created">
                                <OaiTooltip cls="ml-2 text-yellow-600">
                                  <InformationCircleIcon class="h-4 w-4" />
                                  <template #tooltip>
                                    <div>
                                      {{
                                        `Created by: ${mask.created_by}\n${formatTimestampDisplay(
                                          parseTimestamp(mask.created),
                                        )}`
                                      }}
                                    </div>
                                  </template>
                                </OaiTooltip>
                              </div>
                              <button
                                class="ml-2 text-gray-800 hover:underline"
                                v-if="
                                  groupedMasks.length > 1 ||
                                  !groupedMasks.some((item) => !item.validity_start_local) ||
                                  !groupedMasks.some((item) => !item.validity_end_local)
                                "
                                @click="updateImagesAndFocusMasks(getSuitableImageUpdateDate(mask))"
                              >
                                update {{ formatDateDisplay(getSuitableImageUpdateDate(mask)) }}
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                      <tr v-if="copyMaskMode && isCurrentOption(option)">
                        <div class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
                          <div class="flex justify-between">
                            <div class="flex items-center">
                              <select
                                id="level-selector"
                                name="level-name"
                                class="flex-row block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md mr-2"
                                style="min-width: 100px"
                                v-model="copyLevelId"
                              >
                                <option value="" disabled selected>Select a level</option>
                                <option
                                  v-for="levelOption in levelOptionsWithoutCurrentOption"
                                  :key="levelOption"
                                  :value="levelOption"
                                >
                                  {{ levelOption in tagMap ? tagMap[levelOption].name : "" }}
                                </option>
                                <option v-if="hasEmptyLevel" key="empty" :value="null">
                                  Empty Level
                                </option>
                              </select>
                              <button
                                type="button"
                                class="flex-row inline-flex items-center rounded-md px-3 py-2 text-xs font-medium text-white shadow-sm bg-gray-400 hover:bg-gray-600"
                                @click="copyMask(option.building_id, option.section_id)"
                              >
                                <span>Copy Masks</span>
                              </button>
                            </div>
                            <div
                              class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 flex justify-end"
                            >
                              <button @click="copyMaskMode = false" class="pt-2">
                                <XMarkIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { ScissorsIcon, Square2StackIcon, TrashIcon } from "@heroicons/vue/24/outline";
import {
  CheckIcon,
  InformationCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid";
import { addDays, format, parseISO, subDays } from "date-fns";
import { defineComponent, PropType } from "vue";
import { useRoute } from "vue-router";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import logger from "shared/services/logger";
import CameraRepository from "@/repositories/CameraRepository";
import { Drawing } from "@/services/drawing";
import { HierarchyTag, SectionMask, TagCombination } from "@/types/HierarchyTag";
import { Stream } from "@/types/Stream";

export default defineComponent({
  name: "SectionMasks",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    streams: {
      type: Object as PropType<Stream[]>,
      required: true,
    },
    combinations: {
      type: Object as PropType<TagCombination[]>,
      required: true,
    },
    tagMap: {
      type: Object as PropType<Record<string, HierarchyTag>>,
      required: true,
    },
    masks: {
      type: Object as PropType<SectionMask[]>,
      required: true,
    },
    sectionMaskDateRange: {
      type: Object as PropType<Record<string, { start: Date; end: Date }>>,
      required: true,
    },
    formatTagText: {
      type: Function,
      required: true,
    },
  },
  emits: ["closeModal", "updateMasks", "focusMask"],
  components: {
    Modal,
    XMarkIcon,
    CheckIcon,
    PlusIcon,
    Square2StackIcon,
    TrashIcon,
    ScissorsIcon,
    PencilIcon,
    InformationCircleIcon,
    LoadingSpinner,
    OaiTooltip,
  },
  data() {
    return {
      loaded: false as boolean,
      isLoadingImages: false as boolean,
      canvasMap: {} as Record<string, Drawing>,
      currentCanvasId: null as null | string,
      amountColumns: 1 as number,
      imageDate: format(new Date(), "yyyy-MM-dd") as string,
      selectedLevelId: null as string | null,
      processTimeRangeForFilter: { start: null, end: null } as {
        start: null | Date;
        end: null | Date;
      },
      copyLevelId: null as string | null,
      currentOption: null as TagCombination | null,
      editMode: false as boolean,
      drawingMode: true as boolean,
      copyMaskMode: false as boolean,
      validityMaskOption: null as TagCombination | null,
      validityMaskCameraId: null as string | null,
      validityMaskTimestamp: null as string | null,
      validityRangeToEdit: null as {
        mask_id: string;
        start: string | null;
        end: string | null;
      } | null,
    };
  },
  watch: {
    sectionOptions() {
      this.streams.forEach((stream) => {
        if (this.canvasMap && stream.camera_id in this.canvasMap) {
          this.canvasMap[stream.camera_id].setSectionMasks(
            this.getMasksForCamera(stream.camera_id),
          );
        }
      });
    },
    processTimeRangeForFilter(newValue) {
      if (newValue.end || this.sectionOptions.length > 0) {
        this.updateImagesAndFocusMasks(format(newValue.end || new Date(), "yyyy-MM-dd"));
      }
    },
    selectedLevelId() {
      this.onChangeLevel();
    },
    tagMap: {
      deep: true,
      handler() {
        Object.values(this.canvasMap).forEach((canvas) => {
          canvas.setTagMap(this.tagMap);
        });
      },
    },
  },
  async mounted() {
    await this.initializeModal();
    this.loaded = true;
  },
  computed: {
    hasLevels() {
      return this.combinations.some((item) => item.level_id !== null);
    },
    levelOptions() {
      return (
        Array.from(
          new Set(this.combinations.map((item) => item.level_split_id || item.level_id)),
        ).filter((value) => value !== null) as string[]
      ).sort((a, b) => this.tagMap[a].number - this.tagMap[b].number);
    },
    levelOptionsWithoutCurrentOption() {
      if (this.currentOption) {
        return this.levelOptions.filter((option) => {
          return ![this.currentOption?.level_id, this.currentOption?.level_split_id].includes(
            option,
          );
        });
      }
      return [];
    },
    buildingOptions() {
      return (
        [
          ...new Set(
            this.combinations.map((item) => {
              if (
                !this.selectedLevelId ||
                [item.level_id, item.level_split_id].includes(this.selectedLevelId)
              ) {
                return item.building_id;
              }
              return null;
            }),
          ),
        ].filter(Boolean) as string[]
      ).sort((a, b) => this.tagMap[a].number - this.tagMap[b].number);
    },
    sectionOptions() {
      let combinations = [];

      if (this.selectedLevelId === "" && this.hasLevels) {
        combinations = this.combinations.filter(
          (item) => item.building_id === null && item.level_id === null,
        );
      } else {
        combinations = this.combinations.filter((item) =>
          item.level_id && item.level_split_id
            ? [item.level_id, item.level_split_id].includes(this.selectedLevelId as string)
            : item.level_id === (this.selectedLevelId === "" ? null : this.selectedLevelId),
        );
      }
      if (combinations.length > 0) {
        const tagWithSplits = Object.values(this.tagMap).find((tag) =>
          tag.splits?.find((split) => split.id === this.selectedLevelId),
        );

        const levelId = tagWithSplits ? tagWithSplits._id : this.selectedLevelId;
        const levelSplitId = tagWithSplits ? this.selectedLevelId : null;

        combinations.push({
          building_id: null,
          level_id: levelId,
          level_split_id: levelSplitId,
          section_id: null,
        });
      }

      return combinations;
    },
    hasEmptyLevel() {
      return this.combinations.some((option) => option.level_id === null);
    },
    maskMap() {
      return this.masks.reduce((acc, mask) => {
        const key = this.getMaskMapKey(
          mask.building_id,
          mask.level_id,
          mask.level_split_id,
          mask.section_id,
        );
        if (!acc[key]) {
          acc[key] = [mask];
        } else {
          acc[key].push(mask);
        }
        return acc;
      }, {} as Record<string, SectionMask[]>);
    },
  },
  methods: {
    isCurrentOption(option: TagCombination) {
      if (!this.currentOption) {
        return false;
      }
      return (
        this.currentOption.building_id === option.building_id &&
        this.currentOption.level_id === option.level_id &&
        this.currentOption.level_split_id === option.level_split_id &&
        this.currentOption.section_id === option.section_id
      );
    },
    getMaskMapKey(
      building_id: string | null,
      level_id: string | null,
      level_split_id: string | null,
      section_id: string | null,
    ) {
      return `${building_id}#${level_id}#${level_split_id}#${section_id}`;
    },
    hasMask(option: TagCombination) {
      return (
        this.getMaskMapKey(
          option.building_id,
          option.level_id,
          option.level_split_id,
          option.section_id,
        ) in this.maskMap
      );
    },
    getDisplayedBuildingLevelName(building_id: string | null, level_id?: string | null) {
      const tags: HierarchyTag[] = [];
      if (building_id) {
        tags.push(this.tagMap[building_id]);
      }
      if (level_id) {
        tags.push(this.tagMap[level_id]);
      }
      const tagNames = tags.filter((tag) => tag).map((obj) => this.formatTagText(obj));
      return tagNames.join(" | ");
    },
    loadImage(cameraId: string, date?: string, hour?: string) {
      const { customer_name, site_id } = this.route.params;
      return CameraRepository.loadSingleImage(
        customer_name as string,
        site_id as string,
        cameraId as string,
        date as string,
        hour as string,
        false,
      )
        .then((data) => {
          return data;
        })
        .catch((error) => {
          logger.error(error);
          alert(`Unable to load image for: ${cameraId}`);
          return null;
        });
    },
    async getImgMeta(url: string): Promise<HTMLImageElement> {
      const img = new Image();
      img.src = url;
      await img.decode();
      return img;
    },
    async initializeModal() {
      const images = await Promise.all(
        this.streams.map(async (item) => ({
          image: await this.loadImage(item.camera_id),
          cameraId: item.camera_id,
        })),
      );

      this.canvasMap = await images.reduce(async (resultPromise, item) => {
        const result = await resultPromise;
        if (item.image) {
          const sectionDrawing = await this.initializeCanvas(item.cameraId, item.image.url);
          if (sectionDrawing) {
            result[item.cameraId] = sectionDrawing;
          }
        }
        return result;
      }, Promise.resolve({} as Record<string, Drawing>));
    },
    async initializeCanvas(cameraId: string, url: string) {
      const canvas = this.$refs[`canvas-${cameraId}`] as HTMLCanvasElement[];
      if (!canvas[0]) {
        return;
      }
      const { customer_name, site_id } = this.route.params;
      const filteredMasks = this.getMasksForCamera(cameraId);

      const img = await this.getImgMeta(url);
      const sectionDrawing = new Drawing(
        canvas[0],
        img,
        filteredMasks,
        customer_name as string,
        site_id as string,
        cameraId,
        this.tagMap,
      );
      sectionDrawing.drawAllMasks(true);
      return sectionDrawing;
    },
    getMasksForCamera(cameraId: string) {
      return this.masks.filter(
        (mask) =>
          mask.camera_id === cameraId &&
          this.sectionOptions.some(
            (option) =>
              option.building_id === mask.building_id &&
              option.level_id === mask.level_id &&
              option.level_split_id === mask.level_split_id &&
              option.section_id === mask.section_id,
          ),
      );
    },
    undoEventListener() {
      if (this.currentCanvasId) {
        this.canvasMap[this.currentCanvasId].undo();
      }
    },
    async updateImagesAndFocusMasks(date: string | Date | null) {
      if (!date) {
        return;
      }
      this.isLoadingImages = true;
      const dateString = date instanceof Date ? format(date, "yyyy-MM-dd") : date;
      const notFoundCameraIds = [] as string[];

      const images = await Promise.all(
        this.streams.map(async (item) => ({
          image: await this.loadImage(item.camera_id, dateString),
          cameraId: item.camera_id,
        })),
      );
      images.forEach(async (item) => {
        if (item.image) {
          const img = await this.getImgMeta(item.image.url);
          this.canvasMap[item.cameraId]?.setImg(img);
        } else {
          notFoundCameraIds.push(item.cameraId);
        }
      });
      this.imageDate = dateString;
      if (notFoundCameraIds.length > 0) {
        this.showToast(
          "warning",
          `No images found for: ${notFoundCameraIds} (${this.formatDateDisplay(
            new Date(dateString),
          )})`,
        );
      }
      const dateToFocus = new Date(dateString);
      dateToFocus.setHours(12);
      this.focusMasksByDate(dateToFocus);
      this.isLoadingImages = false;
    },
    async changeImageDate() {
      this.updateImagesAndFocusMasks(this.imageDate);
    },
    getGroupedSectionMasksForOption(option: TagCombination) {
      return Object.fromEntries(
        Object.entries(
          this.masks.reduce((result, mask) => {
            if (
              mask.building_id === option.building_id &&
              mask.level_id === option.level_id &&
              mask.level_split_id === option.level_split_id &&
              mask.section_id === option.section_id
            ) {
              if (!result[mask.camera_id]) {
                result[mask.camera_id] = [];
              }
              const insertionIndex = result[mask.camera_id].findIndex((existingMask) => {
                return (
                  existingMask.validity_start_local !== null &&
                  (mask.validity_start_local === null ||
                    new Date(existingMask.validity_start_local) >
                      new Date(mask.validity_start_local))
                );
              });
              if (insertionIndex === -1) {
                result[mask.camera_id].push(mask);
              } else {
                result[mask.camera_id].splice(insertionIndex, 0, mask);
              }
            }
            return result;
          }, {} as Record<string, SectionMask[]>),
        ).sort((a, b) => a[0].localeCompare(b[0])),
      );
    },
    handleEditMask(option: TagCombination) {
      if (!this.isCurrentOption(option)) {
        if (this.currentOption) {
          this.updateSectionMasks(false);
        }
        this.$nextTick(() => {
          this.editMask(option);
        });
      } else {
        this.updateSectionMasks(false);
        this.resetCurrentOption();
      }
      this.resetValidityRangeToEdit();
    },
    editMask(option: TagCombination) {
      const mask = this.masks.find((mask) => {
        return (
          mask.building_id === option.building_id &&
          mask.level_id === option.level_id &&
          mask.level_split_id === option.level_split_id &&
          mask.section_id === option.section_id
        );
      });

      const color = mask
        ? mask.color
        : option.section_id
        ? this.random_rgba()
        : "rgba(30, 64, 175, 1.0)";

      for (const [cameraId, canvas] of Object.entries(this.canvasMap)) {
        canvas.resetCurrentMask();
        canvas.setActiveTag(option);
        canvas.setGlobalColor(color);
        if (this.hasMask(option)) {
          const masksToFocus = (
            this.maskMap[
              this.getMaskMapKey(
                option.building_id,
                option.level_id,
                option.level_split_id,
                option.section_id,
              )
            ] || []
          ).filter((mask) => mask.camera_id === cameraId);

          const mask = masksToFocus.find((mask) => mask.show_on_canvas);
          if (mask) {
            canvas.setCurrentMask(mask._id);
          }
        }
        canvas.unfocusSubMask();
      }
      this.editMode = true;
      this.copyMaskMode = false;
      this.currentOption = option;
    },
    updateSectionMasks(clearEmptyMasks = true) {
      let masksToUpdate: SectionMask[] = [];
      let masksToDelete: SectionMask[] = [];

      Object.values(this.canvasMap).forEach((canvas) => {
        const sectionMasks = canvas.getSectionMasks();
        const uniqueSectionIds = [...new Set(sectionMasks.map((item) => item.section_id))];

        uniqueSectionIds.forEach((sectionId) => {
          if (clearEmptyMasks) {
            const sectionFilteredMasks = sectionMasks.filter(
              (mask) => mask.section_id === sectionId && mask.mask.length > 0,
            );

            const emptyMasks = sectionMasks.filter(
              (mask) => mask.section_id === sectionId && mask.mask.length === 0,
            );
            masksToUpdate = masksToUpdate.concat(sectionFilteredMasks);
            masksToDelete = masksToDelete.concat(emptyMasks);
          } else {
            masksToUpdate = masksToUpdate.concat(
              sectionMasks.filter((mask) => mask.section_id === sectionId),
            );
          }
        });
      });
      if (masksToUpdate.length > 0 || masksToDelete.length > 0) {
        this.$emit("updateMasks", { update: masksToUpdate, delete: masksToDelete });
      }
    },
    resetCurrentOption() {
      this.currentOption = null;
      this.currentCanvasId = null;
      this.editMode = false;
      this.resetValidityRangeToEdit();
      this.$nextTick(() => {
        this.resetAllSectionMasks();
      });
    },
    resetAllSectionMasks(resetMask = true) {
      Object.keys(this.canvasMap).forEach((cameraId) => {
        this.canvasMap[cameraId].setSectionMasks(this.getMasksForCamera(cameraId));
        if (resetMask) {
          this.canvasMap[cameraId].resetCurrentMask();
        }
      });
    },
    resetSingleSectionMask(cameraId: string) {
      if (!this.canvasMap[cameraId]) {
        return;
      }
      this.canvasMap[cameraId].setSectionMasks(this.getMasksForCamera(cameraId));
      this.canvasMap[cameraId].resetCurrentMask();
    },
    random_rgba() {
      const o = Math.round,
        r = Math.random,
        s = 255;
      return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + "," + "1.0)";
    },
    setActiveCanvas(cameraId: string) {
      this.currentCanvasId = cameraId;
    },
    copyMask(building_id: string | null, section_id: string | null) {
      const masksToCopy = this.masks.filter(
        (mask) =>
          [mask.level_id, mask.level_split_id].includes(this.copyLevelId) &&
          mask.building_id === building_id &&
          mask.section_id === section_id &&
          mask.validity_end_local === null,
      );
      if (masksToCopy.length === 0) {
        alert(`No masks found.`);
        return;
      }

      masksToCopy.forEach((maskToCopy) => {
        const canvas = this.canvasMap[maskToCopy.camera_id];
        const currentMask = canvas.getCurrentMask();

        if (!currentMask) {
          canvas.setGlobalColor(maskToCopy.color);
          const newMask = canvas.addMask();
          canvas.setCurrentMask(newMask._id);
        }
        canvas.updateCurrentMaskCoordinates(maskToCopy.mask);
        canvas.drawAllMasks(false);
      });
      this.updateSectionMasks();
    },
    formatDateDisplay(date: Date | null) {
      return date ? format(date, "dd.MM.yyyy") : null;
    },
    formatTimestampDisplay(date: Date) {
      return format(date, "dd.MM.yyyy HH:mm");
    },
    getProcessTimeRangeForSection(option: TagCombination, cameraId: string) {
      const key = `${String(option.building_id)}#${String(this.selectedLevelId)}#${String(
        option.section_id,
      )}#${cameraId}`;
      const timeRangeItem = this.sectionMaskDateRange[key] || null;
      return {
        start: timeRangeItem ? timeRangeItem.start : null,
        end: timeRangeItem ? timeRangeItem.end : null,
      };
    },
    setProcessTimeRangeForFilter() {
      const masksForSelectedFilter = this.masks.filter((item) =>
        [item.level_id, item.level_split_id].includes(this.selectedLevelId),
      );

      const { start, end } = masksForSelectedFilter.reduce(
        (acc, mask) => {
          const possibility_key = `${String(mask.building_id)}#${String(mask.level_id)}#${String(
            mask.level_split_id,
          )}#${String(mask.section_id)}#${mask.camera_id}`;

          if (possibility_key in this.sectionMaskDateRange) {
            const { start, end } = this.sectionMaskDateRange[possibility_key];
            return {
              start: acc.start ? new Date(Math.min(acc.start.getTime(), start.getTime())) : start,
              end: acc.end ? new Date(Math.max(acc.end.getTime(), end.getTime())) : end,
            };
          }
          return acc;
        },
        { start: null as Date | null, end: null as Date | null },
      );
      this.processTimeRangeForFilter = { start, end };
    },
    rearrangeValidityIntervals(masks: SectionMask[], applyOpenEnded = false) {
      masks.sort((a, b) => {
        if (a.validity_start_local === null) return -1;
        if (b.validity_start_local === null) return 1;
        return (
          new Date(a.validity_start_local).getTime() - new Date(b.validity_start_local).getTime()
        );
      });
      if (masks.length > 0) {
        if (applyOpenEnded) {
          masks[0].validity_start_local = null;
        }
        for (let i = 0; i < masks.length - 1; i++) {
          masks[i].validity_end_local = masks[i + 1].validity_start_local;
        }
        if (applyOpenEnded) {
          masks[masks.length - 1].validity_end_local = null;
        }
      }
      return masks;
    },

    getSuitableImageUpdateDate(mask: SectionMask) {
      if (mask.validity_end_local) {
        return subDays(mask.validity_end_local, mask.validity_end_local.getDay() === 1 ? 3 : 1);
      }
      if (mask.validity_start_local) {
        return addDays(mask.validity_start_local, mask.validity_start_local.getDay() === 6 ? 2 : 1);
      }
      return new Date();
    },

    clearMask(mask: SectionMask) {
      this.canvasMap[mask.camera_id].updateCurrentMaskCoordinates([]);
      this.canvasMap[mask.camera_id].render();
    },

    deleteMask(mask: SectionMask) {
      const masks = this.masks.filter(
        (item) =>
          item.building_id === mask.building_id &&
          item.level_id === mask.level_id &&
          item.level_split_id === mask.level_split_id &&
          item.section_id === mask.section_id &&
          item.camera_id === mask.camera_id &&
          item._id !== mask._id,
      );
      this.$emit("updateMasks", { update: masks, delete: [mask] });

      this.$nextTick(() => {
        if (masks.length > 0) {
          this.focusMask(masks[masks.length - 1]);
        } else {
          this.resetSingleSectionMask(mask.camera_id);
        }
      });
    },

    addValidityMask() {
      if (!this.validityMaskOption || !this.validityMaskCameraId) {
        return;
      }
      if (!this.validityMaskTimestamp) {
        this.showToast("warning", "Invalid timestamp. Needs to contain date, hours and minutes.");
        return;
      }

      const timestamp = new Date(this.validityMaskTimestamp);
      const masks = this.masks.filter(
        (item) =>
          this.validityMaskOption &&
          item.building_id === this.validityMaskOption.building_id &&
          item.level_id === this.validityMaskOption.level_id &&
          item.level_split_id === this.validityMaskOption.level_split_id &&
          item.section_id === this.validityMaskOption.section_id &&
          item.camera_id === this.validityMaskCameraId,
      );

      if (
        masks.some(
          (item) =>
            (item.validity_start_local &&
              item.validity_start_local.getTime() === timestamp.getTime()) ||
            (item.validity_end_local && item.validity_end_local.getTime() === timestamp.getTime()),
        )
      ) {
        this.showToast(
          "warning",
          `Identical timestamp already exists as breakpoint : ${this.formatTimestampDisplay(
            timestamp,
          )}`,
        );
        return;
      }

      this.canvasMap[this.validityMaskCameraId].resetCurrentMask();
      const mask = this.canvasMap[this.validityMaskCameraId].addMask(timestamp, null);
      masks.push(mask);
      const updatedMasks = this.rearrangeValidityIntervals(masks);
      this.canvasMap[this.validityMaskCameraId].setCurrentMask(mask._id);

      masks.push(mask);
      this.$emit("updateMasks", { update: updatedMasks, delete: [] });

      this.$nextTick(() => {
        this.focusMask(mask);
      });
    },
    setValidityRangeToEdit(mask: SectionMask) {
      this.validityRangeToEdit = {
        mask_id: mask._id,
        start: mask.validity_start_local
          ? format(mask.validity_start_local, "yyyy-MM-dd'T'HH:mm")
          : mask.validity_start_local,
        end: mask.validity_end_local
          ? format(mask.validity_end_local, "yyyy-MM-dd'T'HH:mm")
          : mask.validity_end_local,
      };
    },
    resetValidityRangeToEdit() {
      this.validityRangeToEdit = null;
    },
    hasValidityRangeOverlap(masks: SectionMask[]): boolean {
      masks.sort((a, b) => {
        if (a.validity_start_local === null) return -1;
        if (b.validity_start_local === null) return 1;
        return (
          new Date(a.validity_start_local).getTime() - new Date(b.validity_start_local).getTime()
        );
      });

      let maxEnd: Date | null = null;

      for (let i = 0; i < masks.length; i++) {
        const start = masks[i].validity_start_local;
        const end = masks[i].validity_end_local;

        const currentStart = start === null ? new Date(-8640000000000000) : new Date(start);
        const currentEnd = end === null ? new Date(8640000000000000) : new Date(end);

        if (currentEnd <= currentStart) {
          return true;
        }

        if (maxEnd !== null && currentStart < maxEnd) {
          return true;
        }

        if (maxEnd === null || currentEnd > maxEnd) {
          maxEnd = currentEnd;
        }
      }

      return false;
    },

    updateValidityMaskRange(mask: SectionMask) {
      const filteredMasks = this.canvasMap[mask.camera_id]
        .getSectionMasks()
        .filter(
          (item) =>
            item.building_id === mask.building_id &&
            item.level_id === mask.level_id &&
            item.level_split_id === mask.level_split_id &&
            item.section_id === mask.section_id,
        );
      const maskToUpdate = filteredMasks.find((item) => item._id === mask._id);

      if (this.validityRangeToEdit && maskToUpdate) {
        maskToUpdate.validity_start_local = this.validityRangeToEdit.start
          ? new Date(this.validityRangeToEdit.start)
          : null;
        maskToUpdate.validity_end_local = this.validityRangeToEdit.end
          ? new Date(this.validityRangeToEdit.end)
          : null;

        if (!this.hasValidityRangeOverlap(filteredMasks)) {
          this.$emit("updateMasks", { update: [maskToUpdate], delete: [] });
          this.resetValidityRangeToEdit();
          this.resetSingleSectionMask(mask.camera_id);
          this.canvasMap[mask.camera_id].setCurrentMask(mask._id);
        } else {
          this.showToast("error", "Validity ranges of section masks are overlapping, please fix!");
        }
      }
    },
    focusMasksByDate(timestamp: Date) {
      const masksToFocus = this.masks.filter(
        (mask) =>
          [mask.level_id, mask.level_split_id].includes(this.selectedLevelId) &&
          (!mask.validity_start_local || mask.validity_start_local <= timestamp) &&
          (!mask.validity_end_local || timestamp < mask.validity_end_local),
      );

      this.$emit("focusMask", masksToFocus, false, this.selectedLevelId);
      this.$nextTick(() => {
        this.streams.forEach((stream) => {
          this.resetSingleSectionMask(stream.camera_id);
        });
        masksToFocus.forEach((mask) => {
          if (
            this.isCurrentOption({
              building_id: mask.building_id,
              level_id: mask.level_id,
              section_id: mask.section_id,
              level_split_id: mask.level_split_id,
            })
          ) {
            this.canvasMap[mask.camera_id].setCurrentMask(mask._id);
          }
        });
      });
    },
    focusMask(mask: SectionMask, toggle = false) {
      this.resetValidityRangeToEdit();
      this.$emit("focusMask", [mask], toggle);
      this.$nextTick(() => {
        this.resetSingleSectionMask(mask.camera_id);
        this.canvasMap[mask.camera_id].setCurrentMask(mask._id);
      });
    },
    handleClosePolygon(cameraId: string) {
      this.canvasMap[cameraId].closePolygon();
      this.$nextTick(() => {
        this.updateSectionMasks(false);
      });
    },
    onChangeLevel() {
      this.updateSectionMasks();
      this.resetCurrentOption();
      this.setProcessTimeRangeForFilter();
    },
    handleOnMouseUp(cameraId: string) {
      if (this.canvasMap[cameraId].getIsDraggingStatus()) {
        this.canvasMap[cameraId]?.onMouseUpDragging();
        this.$nextTick(() => {
          this.updateSectionMasks(false);
        });
      }
    },
    parseTimestamp(timestamp: string) {
      return parseISO(timestamp);
    },
  },
  setup() {
    const route = useRoute();
    return { route };
  },
});
</script>
