<template>
  <div class="flex flex-col gap-6">
    <label
      :key="feature"
      v-for="feature in features"
      class="font-medium select-none flex items-center gap-2"
      :class="isLoading ? 'text-gray-200' : 'text-gray-500'"
    >
      <input
        type="checkbox"
        :checked="project.features.includes(feature)"
        :disabled="isLoading"
        class="h-4 w-4 rounded border-gray-300 text-orange-400 outline-none focus:ring-0 disabled:text-gray-200"
        :style="{ boxShadow: 'none' }"
        @click="handleChange(feature, $event)"
      />
      {{ projectFeatureLabels[feature] || feature }}</label
    >
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import { Project, ProjectFeature } from "shared/types/Project";
import ProjectRepository from "@/repositories/ProjectRepository";
import { projectFeatureLabels } from "@/views/project_console/projectConsole";

const props = defineProps<{ project: Project }>();
const emit = defineEmits<{ (eventName: "update", project: Project): void }>();
const isLoading = ref(false);

const features: ProjectFeature[] = [
  "site_activity",
  "camera",
  "planner",
  "processes",
  "daily_report",
  "working_hours",
];

const handleChange = (feature: ProjectFeature, event: Event) => {
  event.preventDefault();

  const checked = (event.target as HTMLInputElement).checked;

  if (!checked) {
    if (
      !window.confirm(
        `Are you sure to remove feature "${projectFeatureLabels[feature] || feature}"?`,
      )
    ) {
      return;
    }
  }

  isLoading.value = true;

  const updatedFeatures = checked
    ? [...props.project.features, feature]
    : props.project.features.filter((f) => f !== feature);

  ProjectRepository.updateProject(props.project.customer_name, props.project.site_id, {
    features: updatedFeatures,
  })
    .then((project) => {
      emit("update", project);
    })
    .catch((error) => {
      logger.error(error);
      useCustomToast().error("Unable to update features");
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
