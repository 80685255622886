<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-5 xl:w-1/3">
    <template #title>Länder Code/PLZ:</template>
    <template #content>
      <div class="h-[300px] text-left flex flex-col gap-4">
        <div class="flex-1 flex flex-col gap-4">
          <Combobox as="div" v-model="selectedCountry" @update:modelValue="query = ''">
            <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900"
              >Länder Code:</ComboboxLabel
            >
            <div class="relative mt-2">
              <ComboboxInput
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                @change="query = $event.target.value"
                :displayValue="((country: Country) => (country && `${country.name} (${country.code})`)) as (typeof ComboboxInput)['displayValue']"
                autocomplete="one-time-code"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              <ComboboxOptions
                v-if="filteredCountries.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ComboboxOption
                  v-for="country in filteredCountries"
                  :key="country.code"
                  :value="country"
                  as="template"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-orange-600 text-white' : 'text-gray-900',
                    ]"
                  >
                    <span :class="['block truncate', selected && 'font-semibold']">
                      {{ country.name }} ({{ country.code }})
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-orange-600',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>
          <div class="flex flex-col gap-2">
            <div class="text-sm font-medium leading-6 text-gray-900">PLZ:</div>
            <input
              v-model="zipCode"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
            />
          </div>
          <div class="mt-2 flex gap-2 items-center">
            <div
              class="bg-green-200 text-white px-2 py-1 rounded text-sm w-min whitespace-nowrap"
              v-if="weather?.item"
            >
              Weather data found
            </div>
            <div
              class="bg-red-300 text-white px-2 py-1 rounded text-sm w-min whitespace-nowrap"
              v-if="!weather || !weather.item"
            >
              No weather data found
            </div>
            <LoadingSpinner size="w-4 h-4" v-if="isWeatherLoading" />
          </div>
        </div>
        <button
          type="submit"
          :disabled="!selectedCountry || !zipCode || isUpdating"
          class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 disabled:bg-gray-200"
          @click="handleSaveClick"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import debounce from "lodash.debounce";
import { computed, onMounted, ref, watch } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCustomToast } from "shared/composables/toast";
import { countries, Country } from "shared/services/countries";
import logger from "shared/services/logger";
import { Project } from "shared/types/Project";
import { CurrentWeatherResponse } from "shared/types/Weather";
import ProjectRepository from "@/repositories/ProjectRepository";
import WeatherRepository from "@/repositories/WeatherRepository";

const props = defineProps<{ project: Project }>();

const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "update", project: Project): void;
}>();

const query = ref("");
const zipCode = ref(props.project.zip_code);
const selectedCountry = ref<Country | undefined>(
  countries.find((country) => country.code === props.project.country_code),
);
const isUpdating = ref(false);
const weather = ref<CurrentWeatherResponse | null>(null);
const isWeatherLoading = ref(false);

const filteredCountries = computed(() =>
  query.value
    ? countries.filter(
        (country) =>
          country.name.toLowerCase().includes(query.value.toLowerCase()) ||
          country.code.toLowerCase().includes(query.value.toLowerCase()),
      )
    : countries,
);

const handleSaveClick = () => {
  if (!selectedCountry.value || !zipCode.value || isUpdating.value) {
    return;
  }
  if (
    (!weather.value || !weather.value.item) &&
    !window.confirm("No weather data found, are you sure to continue?")
  ) {
    return;
  }
  isUpdating.value = true;
  ProjectRepository.updateProject(props.project.customer_name, props.project.site_id, {
    country_code: selectedCountry.value.code,
    zip_code: zipCode.value,
  })
    .then((project) => {
      emit("update", project);
    })
    .catch((error) => {
      logger.error(error);
      useCustomToast().error("Unable to update project");
    })
    .finally(() => {
      isUpdating.value = false;
    });
};

const isCurrentCountryCode = (countryCode: string, zipCodeToCheck: string) =>
  selectedCountry.value?.code === countryCode && zipCode.value === zipCodeToCheck;

const loadWeatherData = () => {
  if (!selectedCountry.value || !zipCode.value) {
    isWeatherLoading.value = false;
    weather.value = null;
    return;
  }
  weather.value = null;
  const weatherCountryCode = selectedCountry.value.code;
  const weatherZipCode = zipCode.value;
  isWeatherLoading.value = true;
  WeatherRepository.loadByCountryCode(weatherCountryCode, weatherZipCode)
    .then((result) => {
      if (isCurrentCountryCode(weatherCountryCode, weatherZipCode)) {
        weather.value = result;
      }
    })
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      if (isCurrentCountryCode(weatherCountryCode, weatherZipCode)) {
        isWeatherLoading.value = false;
      }
    });
};

const debouncedLoadWeatherData = debounce(loadWeatherData, 300);

onMounted(() => {
  loadWeatherData();
});

watch([selectedCountry, zipCode], () => {
  debouncedLoadWeatherData();
});
</script>
