<template>
  <VideoPlayer
    v-if="showPlayer"
    :src="src!"
    controls
    class="video-js vjs-big-play-centered vjs-4-3"
    :playbackRates="hls ? [] : [0.25, 0.5, 1, 2, 5, 10]"
    muted
    fluid
    fill
    :type="hls ? 'application/x-mpegURL' : undefined"
    @error="handleError"
    :controlBar="{ volumePanel: false }"
    @mounted="handleMounted"
    :html5="{ nativeTextTracks: false }"
    @play="$emit('handlePlay')"
  />
  <template v-if="!showPlayer">
    <div
      v-if="loading && !hasError"
      class="flex items-center justify-center video-aspect-ratio bg-oaiGray-900 relative vjs-waiting shrink-0"
    >
      <div class="vjs-loading-spinner" />
    </div>
    <div v-else class="flex items-start video-aspect-ratio bg-oaiGray-900 shrink-0">
      <div class="rounded-md bg-yellow-400/50 p-4 text-center text-sm text-gray-50 flex-1 m-5">
        {{ hasError || !noSrcMessage ? "Unable to play video" : noSrcMessage }}
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { VideoPlayer } from "@videojs-player/vue";
import videojs from "video.js";
import { defineComponent, PropType } from "vue";
import logger from "shared/services/logger";

import Player = videojs.Player;

export default defineComponent({
  name: "OculaiVideoPlayer",
  props: {
    src: {
      type: String as PropType<string | null>,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    noSrcMessage: {
      type: String,
      required: false,
    },
    hls: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["handlePlay"],
  computed: {
    showPlayer(): boolean {
      return !this.loading && !!this.src && !this.hasError;
    },
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      hasError: false,
    };
  },
  methods: {
    handleError(event: Event) {
      this.hasError = true;
      const target = event.target as unknown as { player: Player };
      const error = target.player.error() as MediaError & { src?: string | null };
      error.src = this.src;
      logger.error("Unhandled error in video player", error);
    },
    handleMounted(event: { player: Player }) {
      // based on https://videojs.com/blog/autoplay-best-practices-with-video-js/
      const playPromise = event.player.play();
      if (playPromise) {
        playPromise.catch((error) => {
          const knownErrors = [
            "The play() request was interrupted",
            "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
            "The fetching process for the media resource was aborted by the user agent at the user's request.",
            "The operation was aborted.",
            "Failed to load because no supported source was found.",
          ];
          if (knownErrors.every((errorMessage) => !error.message.includes(errorMessage))) {
            error.src = this.src;
            logger.error("Unable to auto play video", error);
          }
        });
      }
    },
  },
  watch: {
    src() {
      this.hasError = false;
    },
    loading() {
      this.hasError = false;
    },
  },
});
</script>

<style scoped>
.video-aspect-ratio {
  aspect-ratio: 4 / 3;
}
</style>
