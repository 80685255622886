import * as fabric from "fabric";
import { v4 as uuid } from "uuid";

type AnnotationData = {
  isNew: boolean;
  originalX: number;
  originalY: number;
  type: string;
};

export class Annotation extends fabric.Group {
  id: string;
  data: AnnotationData;

  constructor(options: Partial<fabric.GroupProps> & { data: AnnotationData }) {
    super([], options);

    this.id = uuid();
    this.data = options.data;

    this.controls = this.generateControls();
    this.cornerStyle = "circle";
    this.transparentCorners = false;
  }

  generateControls() {
    return {
      tl: new fabric.Control({
        x: -0.5,
        y: -0.5,
        actionHandler: this.scaleAnnotationHandler.bind(this),
        mouseUpHandler: this.handleFinishScaling.bind(this),
      }),
      tr: new fabric.Control({
        x: 0.5,
        y: -0.5,
        actionHandler: this.scaleAnnotationHandler.bind(this),
        mouseUpHandler: this.handleFinishScaling.bind(this),
      }),
      bl: new fabric.Control({
        x: -0.5,
        y: 0.5,
        mouseUpHandler: this.handleFinishScaling.bind(this),
        actionHandler: this.scaleAnnotationHandler.bind(this),
      }),
      br: new fabric.Control({
        x: 0.5,
        y: 0.5,
        actionHandler: this.scaleAnnotationHandler.bind(this),
        mouseUpHandler: this.handleFinishScaling.bind(this),
      }),
    };
  }

  scaleAnnotationHandler(event: Event, transform: fabric.Transform, x: number, y: number) {
    const mouseEvent = event as MouseEvent;
    const annotation = transform.target as Annotation;
    const objectMeta: Partial<{ left: number; top: number; width: number; height: number }> = {};

    x = Math.round(x);
    y = Math.round(y);

    const original = {
      x1: Math.round(transform.original.left),
      x2: Math.round(transform.original.left) + Math.round(transform.width),
      y1: Math.round(transform.original.top),
      y2: Math.round(transform.original.top) + Math.round(transform.height),
    };

    if (transform.corner === "tl" || transform.corner === "bl") {
      objectMeta.left = Math.min(x, original.x2);
      objectMeta.width = Math.abs(original.x2 - x);
    }

    if (transform.corner === "tr" || transform.corner === "br") {
      objectMeta.left = Math.min(x, original.x1);
      objectMeta.width = Math.abs(original.x1 - x);
    }

    if (transform.corner === "tl" || transform.corner === "tr") {
      objectMeta.top = Math.min(y, original.y2);
      objectMeta.height = Math.abs(original.y2 - y);
    }

    if (transform.corner === "bl" || transform.corner === "br") {
      objectMeta.top = Math.min(y, original.y1);
      objectMeta.height = Math.abs(original.y1 - y);
    }

    annotation.set(objectMeta);
    annotation.item(0).set({
      width: objectMeta.width,
      height: objectMeta.height,
      left: ((objectMeta.width ? objectMeta.width : 0) / 2) * -1,
      top: ((objectMeta.height ? objectMeta.height : 0) / 2) * -1,
    });
    annotation.item(0).setCoords();
    annotation.setCoords();

    this.canvas?.fire("annotation:scaling", { x: mouseEvent.clientX, y: mouseEvent.clientY });

    return true;
  }

  handleFinishScaling() {
    this.canvas?.fire("annotation:scaled");
  }
}
