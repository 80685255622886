import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import Login from "@/views/auth/Login.vue";
import CVDataset from "@/views/cv_dataset/CVDataset.vue";
import Demo from "@/views/demo/Demo.vue";
import Metrics from "@/views/metrics/Metrics.vue";
import PersonGadEditor from "@/views/person_gad/Editor.vue";
import PersonGadOverview from "@/views/person_gad/Overview.vue";
import PlanHierarchy from "@/views/plan_hierarchy/PlanHierarchy.vue";
import Planner from "@/views/planner/Planner.vue";
import GlobalProcessValidation from "@/views/process_validation/GlobalProcessValidation.vue";
import ValidationPrdDate from "@/views/process_validation/ValidationPrdDate.vue";
import ValidationPrdOverview from "@/views/process_validation/ValidationPrdOverview.vue";
import QualitySamplingDetailViewWrapper from "@/views/process_validation/components/QualitySamplingDetailViewWrapper.vue";
import QualitySamplingOverviewView from "@/views/process_validation/components/QualitySamplingOverviewView.vue";
import ProjectConsole from "@/views/project_console/ProjectConsole.vue";
import Streams from "@/views/streams_status/StreamsStatus.vue";
import UnitValuesView from "@/views/unit_values/UnitValuesView.vue";
import ProjectOverview from "@/views/user/UserOverview.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: ValidationPrdOverview,
    name: "ValidationPrdOverview",
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    component: ValidationPrdOverview,
    name: "NotFound",
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/log-in",
    name: "Login",
    component: Login,
  },
  {
    path: "/prd-validation/:customer_name/:site_id/:date",
    name: "ValidationPrdDate",
    component: ValidationPrdDate,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/project-overview",
    name: "ProjectOverview",
    component: ProjectOverview,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/metrics",
    name: "Metrics",
    component: Metrics,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/planner/:customer_name/:site_id",
    name: "Planner",
    component: Planner,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/plan-hierarchy/:customer_name/:site_id",
    name: "PlanHierarchy",
    component: PlanHierarchy,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/global-process-validation",
    name: "GlobalProcessValidation",
    component: GlobalProcessValidation,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/streams",
    name: "Streams",
    component: Streams,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/project-console",
    name: "ProjectConsole",
    component: ProjectConsole,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/unit-values",
    name: "UnitValuesView",
    component: UnitValuesView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/quality-sampling-detail",
    name: "QualitySamplingDetailView",
    component: QualitySamplingDetailViewWrapper,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/quality-sampling-overview",
    name: "QualitySamplingOverviewView",
    component: QualitySamplingOverviewView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/cv-dataset",
    name: "CVDataset",
    component: CVDataset,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/person-gad",
    name: "PersonGadOverview",
    component: PersonGadOverview,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/person-gad/:id",
    name: "PersonGadEditor",
    component: PersonGadEditor,
    meta: {
      requireLogin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.userLoaded && !store.state.user && to.meta.requireLogin) {
    next("/log-in");
  } else {
    next();
  }
});

export default router;
