<template>
  <slot />
</template>

<script lang="ts" setup>
import { provide } from "vue";

const props = defineProps<{ customerName: string; siteId: string }>();

provide("customerName", props.customerName);
provide("siteId", props.siteId);
</script>
