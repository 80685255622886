<template>
  <MainLayout activeItem="Demo">
    <div class="flex h-full">
      <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
        <main class="flex flex-1 overflow-hidden">
          <div class="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
            <nav
              aria-label="project-dropdown-nav"
              class="border-blue-gray-200 border-b bg-white px-8 py-2 xl:hidden"
            >
              <div class="flex h-16 items-center">
                <label for="selected-tab" class="sr-only">Select a tab</label>
                <select
                  id="selected-tab"
                  name="selected-tab"
                  class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
                  @change="changeDemo($event as unknown as Demo)"
                >
                  <option
                    v-for="item in demos"
                    :key="item.project_name"
                    :selected="selectedDemo?._id === item._id"
                    :value="item"
                  >
                    {{ item.project_name }}
                  </option>
                </select>

                <span class="ml-3 pt-1">
                  <button
                    type="button"
                    class="hover:text-yellow-900 focus:ring-2 inline-flex items-center rounded-lg border border-gray-300 bg-white px-2 py-2 text-sm font-bold text-yellow-700 shadow-sm hover:bg-yellow-300 focus:ring-yellow-500 focus:ring-offset-2"
                    @click="addDemo"
                  >
                    <PlusIcon class="h-4 w-4" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </nav>

            <div class="flex flex-1 xl:overflow-hidden">
              <nav
                aria-label="Sections"
                class="border-blue-gray-200 hidden w-96 flex-shrink-0 border-r bg-white xl:flex xl:flex-col"
              >
                <div class="flex h-16 flex-shrink-0 items-center justify-between border-b px-6">
                  <div class="flex items-center">
                    <p class="text-blue-gray-900 text-lg font-medium">Demo</p>
                    <span class="ml-3">
                      <button
                        type="button"
                        class="hover:text-yellow-900 focus:ring-2 inline-flex items-center rounded-lg border border-gray-300 bg-white px-2 py-2 text-sm font-bold text-yellow-700 shadow-sm hover:bg-yellow-300 focus:ring-yellow-500 focus:ring-offset-2"
                        @click="addDemo"
                      >
                        <PlusIcon class="h-4 w-4" aria-hidden="true" />
                      </button>
                    </span>
                  </div>
                  <button
                    v-if="!demos.find((demo) => demo?.is_deploying)"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="deployDemos"
                  >
                    Deploy
                  </button>
                  <button
                    v-else
                    type="button"
                    disabled
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    <LoadingSpinner class="mr-2 text-white" size="h-5 w-5" />
                    Deploying...
                  </button>
                </div>
                <div class="min-h-0 flex-1 overflow-y-auto">
                  <div
                    :id="`project_${item._id}`"
                    v-for="item in demos"
                    :key="item.project_name"
                    href="#"
                    :class="[
                      selectedDemo?._id === item._id
                        ? 'bg-yellow-200'
                        : 'hover:bg-opacity-8 hover:bg-yellow-200',
                      'flex justify-between border-b border-gray-200 p-6 items-center cursor-pointer',
                    ]"
                    :aria-current="selectedDemo?._id === item._id ? 'page' : undefined"
                    @click="changeDemo(item)"
                  >
                    <div class="flex items-center">
                      <BuildingOfficeIcon
                        class="text-blue-gray-400 -mt-0.5 h-6 w-6 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <div class="ml-3 text-sm">
                        <p class="text-gray-900 font-medium">{{ item.project_name }}</p>
                        <p class="mt-1 text-gray-600">
                          {{ item.customer_name }}/{{ item.site_id }}
                        </p>
                      </div>
                    </div>
                    <LoadingSpinner v-if="item.is_deploying" class="mr-2" size="h-5 w-5" />
                  </div>
                </div>
              </nav>

              <div class="flex-1 xl:overflow-y-auto">
                <div class="mx-auto px-12 py-10">
                  <div class="flex items-center">
                    <span class="text-gray-900 text-3xl font-extrabold mr-4">Demo Settings</span>
                    <a
                      v-if="selectedDemo?.last_deployed"
                      :href="`https://demo.oculai.de/${selectedDemo?.customer_name}/${selectedDemo?.site_id}/dashboard`"
                      target="_blank"
                      class="mr-2"
                    >
                      <ArrowTopRightOnSquareIcon class="h-5 w-5 text-gray-900" />
                    </a>
                    <div class="tooltip cursor-pointer">
                      <InformationCircleIcon class="h-5 w-5 text-yellow-600" />
                      <div class="tooltiptext tooltip-right text-sm">
                        <p>
                          <span>Status:</span>

                          <span>{{
                            selectedDemo?.is_deploying
                              ? "Deploying..."
                              : selectedDemo?.last_deployed
                              ? "Deployed"
                              : "Not Deployed"
                          }}</span>
                        </p>

                        <div v-if="selectedDemo?.last_deployed">
                          <p>Last deployment:</p>
                          <p>{{ selectedDemo?.last_deployed_by }}</p>
                          <p>
                            {{ format(parseISO(selectedDemo.last_deployed), "dd.MM.yy, HH:mm:ss") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form class="divide-y-green-gray-200 mt-6 space-y-8 divide-y">
                    <div class="max-w-3xl pt-2">
                      <p class="text-sm font-medium">Demo Project Name</p>
                      <input
                        type="text"
                        placeholder="Name"
                        required
                        v-model="demoName"
                        class="border-gray-300 text-green-gray-900 mt-1 block w-full rounded-md shadow-sm focus:border-yellow-700 focus:ring-yellow-700 sm:text-sm"
                      />
                    </div>

                    <div class="max-w-3xl pt-2">
                      <div class="flex gap-2 items-center">
                        <p class="text-sm font-medium">Project to clone</p>

                        <span
                          v-if="projectToClone?.status !== 'active'"
                          class="ml-2 text-red-500 text-sm font-medium"
                        >
                          (Deprecated)
                        </span>

                        <a
                          v-if="projectToClone"
                          :href="`https://app.oculai.de/${projectToClone.customer_name}/${projectToClone.site_id}/dashboard`"
                          target="_blank"
                          class="ml-1"
                        >
                          <ArrowTopRightOnSquareIcon class="h-5 w-5 text-gray-900" />
                        </a>
                      </div>
                      <Combobox as="div" class="relative mt-2" v-model="projectToClone">
                        <ComboboxInput
                          class="w-full rounded-md border border-gray-300 py-2 pl-2 pr-3 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 sm:text-sm"
                          @change="projectQuery = $event.target.value"
                          :displayValue="
                            ((project: Project) => (`${project.name} (${project.customer_name}/${project.site_id})`)) as (typeof ComboboxInput)['displayValue']
                          "
                        />

                        <ComboboxButton
                          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                          @click="projectQuery = ''"
                        >
                          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>
                        <ComboboxOptions
                          class="focus:outline-none w-full absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5 text-sm"
                        >
                          <ComboboxOption
                            v-for="item in filteredProjects"
                            :key="item._id"
                            :value="item"
                            v-slot="{ active, selected }"
                          >
                            <li
                              :class="[
                                'relative cursor-pointer w-full select-none py-2 pl-3 pr-3 flex align-center justify-between',
                                active ? 'bg-orange-600 text-white' : 'text-gray-900',
                                selected ? 'font-semibold' : '',
                              ]"
                            >
                              <p>
                                {{ item.name }} ({{ item.customer_name }}/{{ item.site_id }})

                                <span
                                  class="bg-red-600 text-white px-1.5 py-0.5 rounded text-xs mr-2"
                                  v-if="item.status !== 'active'"
                                >
                                  Deprecated</span
                                >
                              </p>

                              <CheckIcon v-if="selected" class="h-5 w-5" aria-hidden="true" />
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </Combobox>
                    </div>

                    <div class="max-w-3xl pt-2">
                      <p class="text-sm font-medium">
                        <span>Streams to clone</span>
                        <span v-if="minimumProjectStreamsRequired" class="ml-2 text-gray-500">
                          (min {{ minimumProjectStreamsRequired }})
                        </span>
                        <span v-if="deprecatedStreamsCount" class="ml-2 text-red-500">
                          ({{ deprecatedStreamsCount }} cameras got deprecated. You need to add
                          {{ deprecatedStreamsCount }} cameras more)
                        </span>
                      </p>

                      <div class="flex flex-wrap gap-3 mt-2">
                        <div
                          v-for="item in streamsToClone"
                          :key="item._id"
                          class="bg-gray-100 rounded-md p-2 text-sm font-medium text-gray-900 flex items-center"
                        >
                          <p class="mr-1">
                            {{ item.customer_name }}/{{ item.site_id }} ({{ item.camera_id }})
                          </p>

                          <p
                            :class="[
                              'bg-purple-400 text-gray-50 px-1.5 py-0.5 rounded text-xs mr-2',
                            ]"
                            style="line-height: normal"
                            v-if="item.rtp"
                          >
                            Blur
                          </p>

                          <a
                            :href="`https://app.oculai.de/${item.customer_name}/${item.site_id}/${item.camera_id}/live`"
                            target="_blank"
                            class="ml-1"
                          >
                            <ArrowTopRightOnSquareIcon class="h-4 w-4 text-gray-900" />
                          </a>

                          <XMarkIcon
                            class="h-5 w-5 text-red-500 cursor-pointer"
                            @click="
                              streamsToClone = streamsToClone.filter(
                                (stream) => stream._id !== item._id,
                              )
                            "
                          />
                        </div>
                      </div>

                      <Combobox as="div" class="relative mt-2" multiple v-model="streamsToClone">
                        <ComboboxInput
                          class="w-full rounded-md border border-gray-300 py-2 pl-2 pr-3 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500 sm:text-sm"
                          @change="streamQuery = $event.target.value"
                        />

                        <ComboboxButton
                          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                          @click="streamQuery = ''"
                        >
                          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>
                        <ComboboxOptions
                          class="focus:outline-none w-full absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5 text-sm"
                        >
                          <ComboboxOption
                            v-for="item in filteredStreams"
                            :key="item._id"
                            :value="item"
                            v-slot="{ active, selected }"
                          >
                            <li
                              :class="[
                                'relative cursor-pointer w-full select-none py-2 pl-3 pr-3 flex align-center justify-between',
                                active ? 'bg-orange-600 text-white' : 'text-gray-900',
                                selected ? 'font-semibold' : '',
                              ]"
                            >
                              <p>
                                {{ item.customer_name }}/{{ item.site_id }} ({{ item.camera_id }})
                              </p>
                              <div class="flex items-center gap-3">
                                <p
                                  style="line-height: normal"
                                  :class="[
                                    'bg-purple-300 text-gray-50 px-2 py-1 ml-1 rounded text-xs',
                                    selected && 'font-semibold bg-purple-400',
                                  ]"
                                  v-if="item.rtp"
                                >
                                  Blur
                                </p>
                                <CheckIcon
                                  :class="['h-5 w-5', !selected && 'invisible']"
                                  aria-hidden="true"
                                />
                              </div>
                            </li>
                          </ComboboxOption>
                        </ComboboxOptions>
                      </Combobox>
                    </div>

                    <div class="flex justify-end pt-4 gap-2">
                      <button
                        type="button"
                        @click="deleteDemo"
                        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                      >
                        Delete demo
                      </button>
                      <button
                        type="button"
                        @click="saveDemo"
                        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-700 focus:ring-offset-2"
                      >
                        {{ selectedDemo?._id ? "Update" : "Save" }} demo
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts" setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxButton,
  ComboboxOption,
} from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import {
  BuildingOfficeIcon,
  PlusIcon,
  ChevronUpDownIcon,
  CheckIcon,
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/vue/24/solid";
import { format, parseISO } from "date-fns";
import { ref, onMounted, computed } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCustomToast } from "shared/composables/toast";
import { Project } from "shared/types/Project";
import MainLayout from "@/components/layout/MainLayout.vue";
import DemoRepository from "@/repositories/DemoRepository";
import ProjectRepository from "@/repositories/ProjectRepository";
import StreamRepository from "@/repositories/StreamRepository";
import { Stream } from "@/types/Stream";
import { Demo } from "@/views/demo/types";

const toast = useCustomToast();

const loading = ref(true);
const demos = ref<Demo[]>([]);
const selectedDemo = ref<Demo>();
const projects = ref<Project[]>([]);
const streams = ref<Stream[]>([]);

const demoName = ref("");
const projectToClone = ref<Project | undefined>();
const streamsToClone = ref<Stream[]>([]);
const streamQuery = ref("");
const projectQuery = ref("");

const MAXIMUM_CAMERA_DEPLOYMENT_TIME = 3 * 60 * 1000;

const filteredStreams = computed(() => {
  return streams.value.filter((stream) => {
    const streamIsInQuery = `${stream.customer_name}/${stream.site_id} (${stream.camera_id})`
      .toLowerCase()
      .includes(streamQuery.value.toLowerCase());

    return streamIsInQuery;
  });
});

const filteredProjects = computed(() => {
  return projects.value.filter((project) => {
    const isProjectAlreadyInDemo = demos.value.some((demo) => {
      return (
        project.customer_name === demo.source_customer_name &&
        project.site_id === demo.source_site_id &&
        demo._id !== selectedDemo.value?._id
      );
    });

    if (isProjectAlreadyInDemo) {
      return false;
    }

    const projectIsInQuery = `${project.name} (${project.customer_name}/${project.site_id})`
      .toLowerCase()
      .includes(projectQuery.value.toLowerCase());

    return projectIsInQuery;
  });
});

const minimumProjectStreamsRequired = computed(() => {
  const streamsToConsider = streams.value.filter((stream) => {
    return (
      stream.customer_name === projectToClone.value?.customer_name &&
      stream.site_id === projectToClone.value?.site_id
    );
  });

  return streamsToConsider.length;
});

const deprecatedStreamsCount = computed(() => {
  if (minimumProjectStreamsRequired.value <= streamsToClone.value.length) {
    return 0;
  }

  const nonDeprecatedCameras =
    selectedDemo.value?.cameras.filter((camera) => {
      return streamsToClone.value.find((stream) => stream._id === camera);
    }) || [];

  if (nonDeprecatedCameras.length === selectedDemo.value?.cameras.length) {
    return 0;
  }

  const deprecatedCamerasNeeded = minimumProjectStreamsRequired.value - nonDeprecatedCameras.length;

  return Math.max(0, deprecatedCamerasNeeded);
});

onMounted(async () => {
  const promises = await Promise.all([
    DemoRepository.loadDemos(),
    ProjectRepository.loadAllProjects(),
    StreamRepository.loadAllStreams(),
  ]);

  loading.value = false;

  const demosResponse = promises[0];
  const projectsResponse = promises[1];
  const streamsResponse = promises[2];

  demos.value = demosResponse;
  projects.value = projectsResponse;
  streams.value = streamsResponse;

  if (demos.value.length > 0) {
    changeDemo(demos.value[0]);
  }
});

const addDemo = () => {
  const lastDemo = demos.value.at(-1);

  if (lastDemo && !lastDemo?._id) {
    changeDemo(lastDemo);

    return;
  }

  const newDemo = {
    project_name: "",
    customer_name: "",
    site_id: "",
    source_customer_name: "",
    source_site_id: "",
    cameras: [],
    is_deploying: false,
  } as unknown as Demo;

  demos.value.push(newDemo);
  selectedDemo.value = newDemo;
  demoName.value = newDemo.project_name;
  projectToClone.value = undefined;
  streamsToClone.value = [];
};

const saveDemo = async () => {
  const customer_name = projectToClone.value?.customer_name;
  const site_id = projectToClone.value?.site_id;
  const cameras = streamsToClone.value.map((stream) => stream._id);

  if (!demoName.value) {
    toast.warning("Demo name is required");

    return;
  }

  if (
    demos.value.some((demo) => {
      return demo.project_name === demoName.value && demo._id !== selectedDemo.value?._id;
    })
  ) {
    toast.warning("You cannot create two demos with the same name");

    return;
  }

  if (!customer_name || !site_id) {
    toast.warning("You must select a project to clone");

    return;
  }

  if (streamsToClone.value.length < minimumProjectStreamsRequired.value) {
    toast.warning(`You must select at least ${minimumProjectStreamsRequired.value} streams`);

    return;
  }

  const payload = {
    project_name: demoName.value,
    source_customer_name: customer_name,
    source_site_id: site_id,
    cameras,
  };

  try {
    let updatedDemo: Demo;

    if (selectedDemo.value?._id) {
      updatedDemo = await DemoRepository.updateDemo(selectedDemo.value._id, payload);

      toast.success("Demo updated successfully");
    } else {
      updatedDemo = await DemoRepository.createDemo(payload);

      toast.success("Demo created successfully");
    }

    const index = demos.value.findIndex((demo) => demo._id === updatedDemo._id);

    if (index !== -1) {
      demos.value[index] = updatedDemo;
    } else {
      const updatedDemoList = [...demos.value.slice(0, -1), updatedDemo];

      demos.value = updatedDemoList;
    }

    selectedDemo.value = updatedDemo;
  } catch (e) {
    const error = e as Error;
    toast.error(error.message);
  }
};

const changeDemo = (item: Demo) => {
  selectedDemo.value = item;

  demoName.value = item.project_name;
  projectToClone.value = projects.value.find((project) => {
    return (
      project.customer_name === item.source_customer_name && project.site_id === item.source_site_id
    );
  });

  streamsToClone.value = streams.value.filter((stream) => {
    return item.cameras.includes(stream._id);
  });

  streamQuery.value = "";
};

const deleteDemo = async () => {
  try {
    if (!selectedDemo.value?._id) {
      demos.value.pop();
    } else {
      await DemoRepository.deleteDemo(selectedDemo.value._id);

      toast.success("Demo deleted successfully");

      const index = demos.value.findIndex((demo) => demo._id === selectedDemo.value?._id);

      if (index !== -1) {
        demos.value.splice(index, 1);
      }
    }

    if (demos.value.length > 0) {
      changeDemo(demos.value[0]);
    } else {
      selectedDemo.value = undefined;
      demoName.value = "";
      projectToClone.value = undefined;
      streamsToClone.value = [];
    }
  } catch (e) {
    const error = e as Error;

    toast.error(error.message);
  }
};

const deployDemos = () => {
  const anyDemoIsNotSaved = demos.value.some((demo) => !demo._id);
  const anyDemosAreDeploying = demos.value.some((demo) => demo.is_deploying);

  if (anyDemoIsNotSaved) {
    toast.error("There are unsaved demo");

    return;
  }

  if (anyDemosAreDeploying) {
    toast.error("There are demos already deploying");

    return;
  }

  demos.value.forEach((demo) => (demo.is_deploying = true));

  DemoRepository.deployDemos();

  const camerasCount = demos.value.reduce((acc, demo) => acc + demo.cameras.length, 0);
  const approximateDeploymentTime =
    Math.round((camerasCount * MAXIMUM_CAMERA_DEPLOYMENT_TIME) / 1000 / 60) + 1;

  toast.success(`Demos are deploying. It will take up to ${approximateDeploymentTime} minutes`);
};
</script>
