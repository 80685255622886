<template>
  <div class="flex flex-col w-80 h-full bg-white border-l border-gray-300">
    <div class="flex text-gray-600 items-center justify-between px-5 py-2 border-b border-gray-300">
      <button
        type="button"
        class="inline-flex items-center md:px-4 md:py-1.5 rounded-md font-bold border shadow transition-colors duration-150 text-gray-400 border-gray-400 hover:border-yellow hover:bg-yellow hover:text-white bg-gray-50"
        @click="goBack"
      >
        <ArrowLongLeftIcon class="h-5" />
      </button>

      <div v-if="groupService">
        <button
          type="button"
          :class="[
            'px-2 py-1  border-2 rounded-md transition-colors duration-150 border-yellow hover:text-white hover:bg-yellow mr-2 ',
            !groupService.sequence.completed ? 'text-yellow' : 'bg-yellow text-white',
          ]"
          @click="markSequenceStatus"
        >
          {{ groupService.sequence.completed ? "Reopen" : "Complete" }}
        </button>
        <button
          type="button"
          class="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-700"
          @click="groupService.saveAnnotations()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="flex-1">
      <p class="text-gray-600 px-5 py-2 border-b border-gray-300 font-bold">Annotations</p>

      <ul class="focus flex-1 text-xs pt-4 font-mono" @mouseleave="handleGroupListUnHover">
        <li
          v-for="annotationGroup in annotationGroups"
          :key="annotationGroup.id"
          class="relative text-xs cursor-pointer w-full select-none py-1 pl-2 pr-3 flex hover:bg-gray-200 justify-between"
          @mouseenter="handleGroupHover(annotationGroup.groupId)"
        >
          <div class="flex gap-2 items-center">
            <div class="w-1 h-4" :style="`background: ${annotationGroup.color.main}`" />

            <span v-if="annotationGroup.processClass !== 'no_class'">
              #{{ annotationGroup.groupId }}
            </span>
            <span>
              {{ annotationGroup.processClass }} ({{ annotationGroup.annotations.length }})
            </span>
          </div>
          <div>
            <TrashIcon
              v-if="annotationGroup.processClass !== 'no_class'"
              class="h-4 w-4 text-red"
              @click="groupService?.deleteGroup(annotationGroup)"
            />
          </div>
        </li>
      </ul>
    </div>

    <div class="flex-1">
      <p class="text-gray-600 px-5 py-2 border-b border-t border-gray-300 font-bold">
        Sequence groups
      </p>

      <ul class="focus text-xs pt-4 font-mono" @mouseleave="handleGroupListUnHover">
        <li
          v-for="sequenceGroup in sequenceGroups"
          :key="sequenceGroup.group_id"
          class="relative text-xs w-full select-none py-1 pl-2 pr-3 flex hover:bg-gray-200 justify-between"
          @mouseenter="handleGroupHover(sequenceGroup.group_id)"
        >
          <div
            class="flex gap-2 items-center cursor-pointer"
            v-if="sequenceGroup.process_class !== 'no_class'"
          >
            <div class="w-1 h-full" :style="`background: ${sequenceGroup.color.main}`" />

            <p class="max-w-52 break-words">
              #{{ sequenceGroup.group_id }} {{ sequenceGroup.process_class }}
            </p>
          </div>
          <div class="flex flex-wrap justify-end items-center">
            <ChevronLeftIcon
              class="h-4 w-4 cursor-pointer text-gray-600 5"
              @click="switchToClosestFrameGroup(sequenceGroup.group_id, 'prev')"
            />
            <ChevronRightIcon
              class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
              @click="switchToClosestFrameGroup(sequenceGroup.group_id, 'next')"
            />
            <div v-if="sequenceGroup.process_bbox">
              <EyeIcon
                v-if="sequenceGroup.visible"
                class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
                @click="groupService?.toggleSequenceGroupVisibility(sequenceGroup)"
              />

              <EyeSlashIcon
                v-else
                class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
                @click="groupService?.toggleSequenceGroupVisibility(sequenceGroup)"
              />
            </div>

            <TrashIcon
              class="h-4 w-4 cursor-pointer text-red"
              @click="groupService?.deleteSequenceGroup(sequenceGroup)"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="px-5 py-2 border-t border-gray-300 text-xs font-mono" v-if="groupService?.sequence">
      <div class="flex">
        <span class="text-gray-600 flex-1">Updated by:</span>
        <span class="text-gray-600 flex-1">{{ groupService.sequence.updated_by }}</span>
      </div>
      <div class="flex">
        <span class="text-gray-600 flex-1">Last updated:</span>
        <span class="text-gray-600 flex-1">{{
          format(groupService.sequence.updated, "dd.MM.yy HH:mm:ss")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  TrashIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/vue/24/outline";
import { ArrowLongLeftIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import CanvasService from "../services/canvasService";
import GroupService from "../services/groupService";

const props = defineProps({
  canvasService: {
    type: Object as PropType<CanvasService>,
  },
  groupService: {
    type: Object as PropType<GroupService>,
  },
  query: {
    type: Object as PropType<URLSearchParams>,
  },
});

const router = useRouter();
const toast = useCustomToast();

const annotationGroups = computed(() => {
  if (props?.groupService?.currentFrameGroups) {
    return props.groupService.currentFrameGroups.slice().sort((a, b) => a.groupId - b.groupId);
  }

  return [];
});

const sequenceGroups = computed(() => {
  const groupService = props.groupService;

  if (groupService) {
    return groupService.sequenceGroups
      .map((group) => ({
        ...group,
        color: groupService.getProcessClassColor(group.process_class),
      }))
      .sort((a, b) => a.group_id - b.group_id);
  }

  return [];
});

const handleGroupListUnHover = () => {
  props.canvasService?.revertAnnotationsHighlight();
};

const handleGroupHover = (groupId: number) => {
  const annotationGroup = annotationGroups.value.find((group) => group.groupId === groupId);
  if (!annotationGroup) {
    return;
  }

  props.canvasService?.highlightAnnotations(annotationGroup.annotations);
};

const switchToClosestFrameGroup = (groupId: number, direction: "prev" | "next") => {
  const closestFrameIndex = props.groupService?.getClosestFrameIndexWithGroup(groupId, direction);

  if (closestFrameIndex !== undefined && closestFrameIndex !== props.groupService?.frameIndex) {
    props.groupService?.setFrameIndex(closestFrameIndex);
  }
};

const markSequenceStatus = async () => {
  if (!props.groupService) {
    return;
  }

  try {
    await props.groupService.saveAnnotations(!props.groupService.sequence.completed);
    toast.success("Sequence status updated");
  } catch (e: unknown) {
    logger.error(e);
    toast.error("Something went wrong");
  }
};

const goBack = () => {
  router.push(`/person-gad?${props.query?.toString()}`);
};
</script>
