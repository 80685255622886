<template>
  <div class="tooltip cursor-pointer">
    <InformationCircleIcon class="h-5 w-5 text-yellow-600" />
    <div class="tooltiptext tooltip-right text-xs text-left">
      <div class="text-base mb-1">Workforce</div>
      <div v-if="!hasAnyWorkforceInfo">no data</div>
      <div v-if="workforce['55_percentile'] !== null">
        55 percentile:
        {{ workforce["55_percentile"] }}
      </div>
      <div v-if="workforce['60_percentile'] !== null">
        60 percentile:
        {{ workforce["60_percentile"] }}
      </div>
      <div v-if="workforce['65_percentile'] !== null">
        65 percentile:
        {{ workforce["65_percentile"] }}
      </div>
      <div v-if="workforce['70_percentile'] !== null">
        70 percentile:
        {{ workforce["70_percentile"] }}
      </div>
      <div v-if="workforce['75_percentile'] !== null">
        75 percentile:
        {{ workforce["75_percentile"] }}
      </div>
      <div v-if="workforce['80_percentile'] !== null">
        80 percentile:
        {{ workforce["80_percentile"] }}
      </div>
      <div v-if="workforce['85_percentile'] !== null">
        85 percentile:
        {{ workforce["85_percentile"] }}
      </div>
      <div v-if="workforce['90_percentile'] !== null">
        90 percentile:
        {{ workforce["90_percentile"] }}
      </div>
      <div v-if="workforce['95_percentile'] !== null">
        95 percentile:
        {{ workforce["95_percentile"] }}
      </div>
      <div v-if="workforce.median !== null">
        Median:
        {{ workforce.median.toFixed(2) }}
      </div>
      <div v-if="workforce.mean !== null">Mean: {{ workforce.mean.toFixed(2) }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { computed, PropType } from "vue";
import { ProcessWorkforce } from "shared/types/Process";

const props = defineProps({
  workforce: {
    type: Object as PropType<ProcessWorkforce>,
    required: true,
  },
});

const hasAnyWorkforceInfo = computed(() =>
  Object.entries(props.workforce)
    .filter(([key]) => key !== "validated_count")
    .some(([, value]) => value !== null),
);
</script>
