import { useQuery } from "@tanstack/vue-query";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import SectionMasksRepository from "@/repositories/SectionMasksRepository";
import { SectionMask } from "@/types/HierarchyTag";

export const useMostRecentSectionMasks = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
) => {
  const { data, isLoading } = useQuery<{ [key: string]: SectionMask[] }>({
    queryKey: ["section-masks-most-recent", customerName, siteId, cameraId, date],
    queryFn: () =>
      SectionMasksRepository.loadMostRecentSectionMasks(customerName, siteId, cameraId, date),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load most recent section masks");
      return false;
    },
  });

  return { sectionMasks: data, areSectionMasksLoading: isLoading };
};
