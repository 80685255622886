import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import { ProjectStatus } from "shared/types/Project";
import { ProjectExportResponse, ValidationSummaryExportResponse } from "@/types/Controlling";

const loadExportOpsReviewProcesses = (
  customerName: string,
  siteId: string,
  startDate: string,
  endDate: string,
): Promise<ProjectExportResponse> =>
  apiClient
    .get<void, AxiosResponse<ProjectExportResponse>>(
      `/prd-validation/ops-review/processes-export/${customerName}/${siteId}/${startDate}/${endDate}`,
    )
    .then((response) => response.data);

const loadPrdValidationSummaryExportData = (
  startDate: string,
  endDate: string,
  projectStatus?: ProjectStatus | null,
  userFilter?: string | null,
): Promise<ValidationSummaryExportResponse> => {
  const searchParams = new URLSearchParams();
  if (projectStatus) {
    searchParams.set("project_status", projectStatus);
  }
  if (userFilter) {
    searchParams.set("user_filter", userFilter);
  }

  return apiClient
    .get<void, AxiosResponse<ValidationSummaryExportResponse>>(
      `/prd-validation/ops-review/validation-export/${startDate}/${endDate}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

export default {
  loadExportOpsReviewProcesses,
  loadPrdValidationSummaryExportData,
};
