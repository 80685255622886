// sort-imports-ignore
// this has to be the first import
import i18n from "./i18n";

import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";
import Amplify, { Auth } from "aws-amplify";
import { apiClient } from "shared/repositories/clients";
import { createApp, ComponentOptions } from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import GlobalMixins from "shared/mixins/GlobalMixins";
import { getConfig } from "@/services/config";
import App from "./App.vue";
import awsconfig from "./aws-exports";
import ToastMixins from "shared/mixins/ToastMixins";
import router from "./router";
import logger from "shared/services/logger";
import store from "./store";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const app = createApp(App);

logger.init(app, router);

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        staleTime: 15000,
        useErrorBoundary: (error: unknown) => {
          logger.error(error);
          return false;
        },
      },
      mutations: {
        onError: (error: unknown) => {
          logger.error(error);
        },
      },
    },
  },
};

apiClient.defaults.baseURL = getConfig().API_BASE;

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast)
  .mixin(GlobalMixins as ComponentOptions)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .mixin(ToastMixins as ComponentOptions)
  .mount("#app");
