<template>
  <Modal :open="open" customCls="w-11/12" @close="$emit('closeModal')">
    <template #title>
      <div class="flex items-center justify-between">
        <div class="flex">
          <div class="inline-flex">Process Planner Mapping</div>
        </div>
        <div class="flex font-normal space-x-2">
          <span
            >Mapped: <b>{{ mappingStatistics.mapped }}</b></span
          >
          <span
            >Conflict: <b>{{ mappingStatistics.mapping_problem }}</b></span
          >
          <span
            >No Mask: <b>{{ mappingStatistics.no_mask_mapping }}</b></span
          >
        </div>
        <div class="flex space-x-1 font-normal" style="margin-right: 30px">
          <select
            v-model="filterValue"
            id="users"
            name="users"
            class="w-40 block rounded-md border-gray-300 py-1 pr-2 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
          >
            <option default value="all">All Processes</option>
            <option value="mapped">Mapped</option>
            <option value="mapping_problem">Mapping Conflict</option>
            <option value="no_mask_mapping">No Mask</option>
          </select>
          <button
            type="button"
            class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-blue-700 hover:bg-blue-800"
            :disabled="remapLoading"
            @click="$emit('remapAllProcesses')"
          >
            <div class="pr-2" v-if="remapLoading">
              <LoadingSpinner :cls="'text-white'" size="h-5 w-5" />
            </div>
            <span>Remap All Processes</span>
          </button>
        </div>
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-500" />
    </template>
    <template #content>
      <div class="-my-2 overflow-x-auto max-h-[80vh]">
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full divide-y divide-gray-300">
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                v-for="process in filteredProcesses"
                :key="process._id"
                :class="resolveMappingStatus(process)"
              >
                <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                  {{ getDecodedLabel(process.encoded_label as EncodedLabel) }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  <span class="font-semibold mr-1">{{ formatIsoDate(process.date) }}: </span>
                  {{ formatIsoHourMinute(process.start_time) }} -
                  {{ formatIsoHourMinute(process.end_time) }}
                  {{
                    process.work_intervals.length > 1 ? `(${process.work_intervals.length})` : ""
                  }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  {{ getSectionMaskName(process) }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  {{
                    process.planner_item_mapping.source_id
                      ? process.planner_item_mapping.item_name
                      : "-"
                  }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  {{ process.camera_id }}
                </td>
                <td class="w-10 whitespace-nowrap py-2 text-right text-sm">
                  <div>
                    <router-link
                      :to="{
                        name: 'ValidationPrdDate',
                        params: {
                          date: `${process.date}`,
                          customer_name: `${process.customer_name}`,
                          site_id: `${process.site_id}`,
                        },
                        query: {
                          mode: 'ops',
                          camera_id: process.camera_id,
                        },
                      }"
                      class="hover:text-yellow-700 h-full p-2 flex items-center"
                    >
                      <ArrowDownLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { ArrowDownLeftIcon } from "@heroicons/vue/20/solid";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { EncodedLabel } from "shared/types/ProcessClass";
import { useProcessClasses } from "@/composables/process";
import { MappingProcess } from "@/types/Validation";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

export default defineComponent({
  name: "PlannerProcessMapping",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    processes: {
      type: Object as PropType<MappingProcess[]>,
      required: true,
    },
    remapLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["closeModal", "remapAllProcesses"],
  components: {
    Modal,
    LoadingSpinner,
    ArrowDownLeftIcon,
  },
  data() {
    return {
      filterValue: "all" as "all" | "mapped" | "mapping_problem" | "no_mask_mapping",
      filterOptions: ["all", "mapped", "mapping_problem", "no_mask_mapping"],
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    return {
      processClasses,
    };
  },
  computed: {
    filteredProcesses(): MappingProcess[] {
      if (this.filterValue === "mapped") {
        return this.processes.filter((item) => item.planner_item_mapping.source_id);
      }
      if (this.filterValue === "mapping_problem") {
        return this.processes.filter(
          (item) => !item.planner_item_mapping.source_id && item.section_mask_mapping.id,
        );
      }
      if (this.filterValue === "no_mask_mapping") {
        return this.processes.filter(
          (item) => !item.planner_item_mapping.source_id && !item.section_mask_mapping.id,
        );
      }
      return this.processes;
    },
    mappingStatistics(): { mapped: number; mapping_problem: number; no_mask_mapping: number } {
      return {
        mapped: this.processes.filter((item) => item.planner_item_mapping.source_id).length,
        mapping_problem: this.processes.filter(
          (item) => !item.planner_item_mapping.source_id && item.section_mask_mapping.id,
        ).length,
        no_mask_mapping: this.processes.filter(
          (item) => !item.planner_item_mapping.source_id && !item.section_mask_mapping.id,
        ).length,
      };
    },
  },
  methods: {
    getDecodedLabel(encodedLabel: EncodedLabel) {
      return this.processClasses.find((item) => item.encodedLabel === encodedLabel)?.decodedLabel;
    },
    formatIsoHourMinute(isoString: string) {
      return format(parseUtcDate(isoString), "HH:mm");
    },
    formatIsoDate(isoString: string) {
      return format(parseISO(isoString), "dd.MM.yyyy");
    },
    resolveMappingStatus(process: MappingProcess) {
      if (process.planner_item_mapping.source_id) {
        return "mapped";
      }
      if (process.section_mask_mapping.id) {
        return "mapping_problem";
      }
      return "no_mask_mapping";
    },
    getSectionMaskName(process: MappingProcess) {
      const names: string[] = [];

      if (process.section_mask_mapping.building_name) {
        names.push(process.section_mask_mapping.building_name);
      }
      if (process.section_mask_mapping.level_name) {
        names.push(process.section_mask_mapping.level_name);
      }
      if (process.section_mask_mapping.section_name) {
        names.push(process.section_mask_mapping.section_name);
      }
      return names.join(" | ");
    },
  },
});
</script>
<style scoped>
.mapped {
  @apply bg-green-100;
}
.mapping_problem {
  @apply bg-red-200;
}
.no_mask_mapping {
  @apply bg-yellow-100;
}
</style>
