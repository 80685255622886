<template>
  <MainLayout activeItem="CVDataset">
    <div class="flex flex-col max-h-screen h-full overflow-hidden">
      <nav
        aria-label="Sections"
        class="border-blue-gray-200 flex bg-white items-center justify-between border-b py-4 px-6"
      >
        <p class="text-blue-gray-900 text-lg font-medium">CV Person Dataset</p>
        <button
          type="button"
          :disabled="isBatchCreating"
          :class="[
            'rounded-md px-4 py-2 text-base font-medium text-white shadow-sm',
            isBatchCreating
              ? 'cursor-not-allowed bg-gray-400'
              : 'bg-yellow-500 hover:bg-yellow-600',
          ]"
          @click="createBatch"
        >
          {{ isBatchCreating ? "Creating batch..." : "Create batch" }}
        </button>
      </nav>
      <div
        :id="galleryId"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-6 sm:gap-x-6 md:grid-cols-6 lg:grid-cols-6 2xl:grid-cols-8 xl:gap-x-8 px-12 py-10 overflow-y-auto"
      >
        <div v-for="image in images" :key="image.url">
          <a
            :href="image.url"
            :data-pswp-height="1920"
            :data-pswp-width="2560"
            :data-timestamp="image.timestamp"
            :data-customer-name="image.customer"
            :data-site-id="image.site_id"
            :data-camera-id="image.camera_id"
            :data-id="image.image"
            target="_blank"
            rel="noreferrer"
          >
            <div
              class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
            >
              <img
                :src="image.url"
                class="group-hover:opacity-75 object-cover pointer-events-none"
                alt="gallery image"
              />
            </div>
          </a>
          <p class="text-xs mt-2">{{ image.customer }}/{{ image.site_id }}/{{ image.camera_id }}</p>
        </div>
      </div>
      <div v-if="imagesLoading" class="flex-1 flex items-center justify-center">
        <LoadingSpinner class="text-gray-500 absolute" size="h-6 w-6" />
      </div>
      <div class="flex-1 flex items-center justify-center relative" v-else-if="!images.length">
        <p class="text-xl font-bold absolute">No images in the dataset</p>
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts" setup>
import "photoswipe/dist/photoswipe.css";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { onMounted, onUnmounted, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import MainLayout from "@/components/layout/MainLayout.vue";
import CameraRepository from "@/repositories/CameraRepository";
import { DatasetPersonImage } from "@/types/Camera";

const toast = useCustomToast();

const images = ref<DatasetPersonImage[]>([]);
const lightbox = ref<PhotoSwipeLightbox | null>(null);
const galleryId = "cv_dataset_gallery";
const imagesLoading = ref(true);
const isBatchCreating = ref(false);

onMounted(async () => {
  const personImagesDataset = await CameraRepository.getPersonImagesDataset();

  personImagesDataset.sort((a, b) => {
    if (a.customer === b.customer) {
      if (a.site_id === b.site_id) {
        return a.camera_id.localeCompare(b.camera_id);
      }
      return a.site_id.localeCompare(b.site_id);
    }
    return a.customer.localeCompare(b.customer);
  });

  imagesLoading.value = false;
  images.value = personImagesDataset;

  mountPhotoSwipe();
});

onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy();
    lightbox.value = null;
  }
});

const mountPhotoSwipe = () => {
  if (!lightbox.value) {
    const lightboxInstance = new PhotoSwipeLightbox({
      gallery: `#${galleryId}`,
      children: "a",
      pswpModule: () => import("photoswipe"),
      wheelToZoom: true,
      padding: { top: 10, bottom: 10, left: 10, right: 10 },
    });

    lightboxInstance.on("uiRegister", () => {
      lightboxInstance.pswp?.ui?.registerElement({
        name: "annotation-button",
        order: 7,
        isButton: true,
        tagName: "p",
        className: "!w-auto !flex !items-center",
        html: '<p class="px-3 py-2 rounded-md bg-red-600 text-white text-xs whitespace-nowrap">Delete from dataset</p>',

        onClick(e, el, pswp) {
          const customerName = pswp.currSlide?.data?.element?.dataset?.customerName;
          const siteId = pswp.currSlide?.data?.element?.dataset?.siteId;
          const cameraId = pswp.currSlide?.data?.element?.dataset?.cameraId;
          const timestamp = pswp.currSlide?.data?.element?.dataset?.timestamp;
          const id = pswp.currSlide?.data?.element?.dataset?.id;

          if (!timestamp || !customerName || !siteId || !cameraId) {
            return;
          }

          CameraRepository.deletePersonImageFromDatasetBuffer(
            customerName,
            siteId,
            cameraId,
            timestamp,
          )
            .then(() => {
              toast.success("Image successfully deleted from dataset buffer");
              images.value = images.value.filter((image) => image.image !== id);
            })
            .catch((error) => {
              logger.error(error);
              toast.error("Failed to delete image");
            })
            .finally(() => {
              if (images.value.length > 0) {
                pswp.next();
              } else {
                pswp.close();
              }
            });
        },
      });
    });

    lightboxInstance.init();
    lightbox.value = lightboxInstance;
  }
};

const createBatch = async () => {
  isBatchCreating.value = true;
  try {
    await CameraRepository.createBatchFromPersonDatasetBuffer();

    toast.success("Batch was successfully created");

    images.value = [];
  } catch (_) {
    toast.error("Batch failed to create");
    logger.error("Failed to create batch from person dataset buffer");
  } finally {
    isBatchCreating.value = false;
  }
};
</script>
