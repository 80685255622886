export const GROUP_COLORS = [
  {
    main: "#FF0000",
    transparent: "rgba(255, 0, 0, 0.3)",
    transparentLight: "rgba(255, 0, 0, 0.1)",
  },
  {
    main: "#00FF00",
    transparent: "rgba(0, 255, 0, 0.3)",
    transparentLight: "rgba(0, 255, 0, 0.1)",
  },
  {
    main: "#0000FF",
    transparent: "rgba(0, 0, 255, 0.3)",
    transparentLight: "rgba(0, 0, 255, 0.1)",
  },
  {
    main: "#FFFF00",
    transparent: "rgba(255, 255, 0, 0.3)",
    transparentLight: "rgba(255, 255, 0, 0.1)",
  },
  {
    main: "#00FFFF",
    transparent: "rgba(0, 255, 255, 0.3)",
    transparentLight: "rgba(0, 255, 255, 0.1)",
  },
  {
    main: "#FF00FF",
    transparent: "rgba(255, 0, 255, 0.3)",
    transparentLight: "rgba(255, 0, 255, 0.1)",
  },
  {
    main: "#FFA500",
    transparent: "rgba(255, 165, 0, 0.3)",
    transparentLight: "rgba(255, 165, 0, 0.1)",
  },
  {
    main: "#800080",
    transparent: "rgba(128, 0, 128, 0.3)",
    transparentLight: "rgba(128, 0, 128, 0.1)",
  },
  {
    main: "#008000",
    transparent: "rgba(0, 128, 0, 0.3)",
    transparentLight: "rgba(0, 128, 0, 0.1)",
  },
  {
    main: "#800000",
    transparent: "rgba(128, 0, 0, 0.3)",
    transparentLight: "rgba(128, 0, 0, 0.1)",
  },
  {
    main: "#000080",
    transparent: "rgba(0, 0, 128, 0.3)",
    transparentLight: "rgba(0, 0, 128, 0.1)",
  },
  {
    main: "#808000",
    transparent: "rgba(128, 128, 0, 0.3)",
    transparentLight: "rgba(128, 128, 0, 0.1)",
  },
  {
    main: "#800080",
    transparent: "rgba(128, 0, 128, 0.3)",
    transparentLight: "rgba(128, 0, 128, 0.1)",
  },
  {
    main: "#008080",
    transparent: "rgba(0, 128, 128, 0.3)",
    transparentLight: "rgba(0, 128, 128, 0.1)",
  },
  {
    main: "#FF0000",
    transparent: "rgba(255, 0, 0, 0.3)",
    transparentLight: "rgba(255, 0, 0, 0.1)",
  },
  {
    main: "#00FF00",
    transparent: "rgba(0, 255, 0, 0.3)",
    transparentLight: "rgba(0, 255, 0, 0.1)",
  },
  {
    main: "#0000FF",
    transparent: "rgba(0, 0, 255, 0.3)",
    transparentLight: "rgba(0, 0, 255, 0.1)",
  },
  {
    main: "#FFFF00",
    transparent: "rgba(255, 255, 0, 0.3)",
    transparentLight: "rgba(255, 255, 0, 0.1)",
  },
  {
    main: "#00FFFF",
    transparent: "rgba(0, 255, 255, 0.3)",
    transparentLight: "rgba(0, 255, 255, 0.1)",
  },
  {
    main: "#FF00FF",
    transparent: "rgba(255, 0, 255, 0.3)",
    transparentLight: "rgba(255, 0, 255, 0.1)",
  },
];

export type GroupColor = (typeof GROUP_COLORS)[number];
