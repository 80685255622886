<template>
  <div v-show="show" class="absolute top-0 left-0 w-full h-full">
    <div
      class="absolute left-0 w-full"
      :style="`background: #ffffff90; height: 1px; top: ${y}px`"
    />
    <div class="absolute top-0 h-full" :style="`background: #ffffff90; width: 1px; left: ${x}px`" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import Cursor from "@/assets/imgs/editorCursor.png";
import CanvasService from "../services/canvasService";

const props = defineProps<{ canvasService: CanvasService }>();

const x = ref(0);
const y = ref(0);
const show = ref(false);

onMounted(() => {
  props.canvasService.on("annotation:scaling", enableCrosshair);
  props.canvasService.on("annotation:scaled", disableCrosshair);
});

const enableCrosshair = (event: { x: number; y: number }) => {
  x.value = event.x;
  y.value = event.y;
  show.value = true;
  window.document.body.style.cursor = `url(${Cursor}) 18 18, auto`;
};

const disableCrosshair = () => {
  show.value = false;
  window.document.body.style.cursor = "default";
};
</script>
